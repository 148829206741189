import { SID_AG_BASE_URL } from 'Modules/shareholders-id/constants';
import { formatDate } from '@/lib/date';

const Ctrl = [
  'AG_SidRequestRepository',
  'AG_IssuerRepository',
  '$scope',
  '$state',
  function (SidRequestRepository, issuerRepository, $scope, $state) {
    this.$onInit = () => {
      this.issuerFilterOptions = [];
      this.issuerFilterValue = null;

      this.sidRequestsLandingPageState = {
        pageNo: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
        loading: false,
        status: 'in_progress',
        sidRequests: [],
      };

      this.readFilterFromLocation();

      this.statusTabs = [
        {
          key: 'all',
          label: 'All',
        },
        {
          key: 'draft',
          label: 'Draft',
        },
        {
          key: 'awaiting_agent_approval',
          label: 'Awaiting agent approval',
        },
        {
          key: 'awaiting_issuer_approval',
          label: 'Awaiting issuer approval',
        },
        {
          key: 'rejected',
          label: 'Rejected',
        },
        {
          key: 'in_progress',
          label: 'In progress',
        },
        {
          key: 'completed',
          label: 'Completed',
        },
        {
          key: 'cancelled',
          label: 'Cancelled',
        },
      ];

      this.fetchSidRequests().then(() => {
        this.fetchSidIssuers();
      });
    };

    this.constructParams = () => {
      const { pageNo, pageSize, status } = this.sidRequestsLandingPageState;
      const params = {
        pageNo,
        pageSize,
      };
      if (status !== 'all') {
        params.status = status;
      }

      if (this.issuerFilterValue) {
        params.issuerId = this.issuerFilterValue;
      }
      return params;
    };

    this.fetchSidRequests = async () => {
      this.sidRequestsLandingPageState.loading = true;
      try {
        const page = await SidRequestRepository.list(this.constructParams());
        this.formatDates(page.content);
        this.sidRequestsLandingPageState.sidRequests = page.content;
        this.sidRequestsLandingPageState.totalElements = page.totalElements;
      } catch (err) {
        this.sidRequestsLandingPageState.error = err;
      } finally {
        this.sidRequestsLandingPageState.loading = false;
        $scope.$apply();
      }
    };

    this.fetchSidIssuers = async () => {
      this.isFetchingIssuers = true;
      try {
        const issuers = await issuerRepository.listAllGM();
        this.issuerFilterOptions = [
          ...issuers.map((i) => ({
            name: i.name,
            value: i.id,
          })),
        ];

        if (this.issuerFilterValue) {
          this.comboboxText =
            this.issuerFilterOptions &&
            this.issuerFilterOptions.find(
              (option) => option.value === this.issuerFilterValue
            )?.name;
        }
      } finally {
        this.isFetchingIssuers = false;
        $scope.$apply();
      }
    };

    this.openSidRequest = (sidRequest) => {
      if (sidRequest.status === 'DRAFT') {
        $state.go('shareholders-id.agent.sidRequestDetails.edit', {
          sidRequestId: sidRequest.sidRequestId,
        });
      } else if (
        sidRequest.status === 'IN_PROGRESS' ||
        sidRequest.status === 'COMPLETED'
      ) {
        $state.go('shareholders-id.agent.sidRequestDetails.results.overview', {
          sidRequestId: sidRequest.sidRequestId,
        });
      } else {
        $state.go('shareholders-id.agent.sidRequestDetails.overview', {
          sidRequestId: sidRequest.sidRequestId,
        });
      }
    };

    this.handlePaginationChanged = (event) => {
      const { itemsPerPage, pageNumber } = event.detail.value;
      if (
        itemsPerPage === this.sidRequestsLandingPageState.pageSize &&
        pageNumber === this.sidRequestsLandingPageState.pageNo
      ) {
        // Nothing changed
        return;
      }

      this.sidRequestsLandingPageState.pageSize = itemsPerPage;
      this.sidRequestsLandingPageState.pageNo = pageNumber;
      this.syncFilterToLocation();
      this.fetchSidRequests();
    };

    this.handleStatusChanged = (status) => {
      this.sidRequestsLandingPageState.status = status;
      this.sidRequestsLandingPageState.pageNo = 1;
      this.sidRequestsLandingPageState.sidRequests = [];
      this.syncFilterToLocation();
      this.fetchSidRequests();
    };

    this.handleIssuerFilterChange = (event) => {
      this.issuerFilterValue = event.detail.value;
      this.syncFilterToLocation();
      this.fetchSidRequests();
    };

    // Save/store the filter on the browser location (query params)
    this.readFilterFromLocation = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const issuerId = queryParams.get('issuerId');
      const status = queryParams.get('status');
      const pageSize = queryParams.get('pageSize');
      const pageNo = queryParams.get('pageNo');

      if (issuerId) {
        this.issuerFilterValue = issuerId;
        this.comboboxText = this.issuerFilterOptions?.find(
          (option) => option.value === issuerId
        )?.name;
      }
      if (status) {
        this.sidRequestsLandingPageState.status = status;
      }
      if (pageNo) {
        this.sidRequestsLandingPageState.pageNo = pageNo;
      }
      if (pageSize) {
        this.sidRequestsLandingPageState.pageSize = pageSize;
      }
    };

    this.syncFilterToLocation = () => {
      let queryString = '?';
      if (this.issuerFilterValue) {
        queryString += `issuerId=${this.issuerFilterValue}&`;
      }
      const { pageNo, pageSize, status } = this.sidRequestsLandingPageState;
      queryString += `pageSize=${pageSize}&pageNo=${pageNo}&status=${status}`;
      window.history.replaceState(
        {},
        null,
        window.location.pathname + queryString
      );
    };

    this.handleComboboxTextChange = (text) => {
      if (!text) {
        this.comboboxOptions = [];
      } else {
        this.comboboxOptions = this.issuerFilterOptions.filter((option) =>
          option.name.toLowerCase().includes(text.toLowerCase())
        );
      }
    };

    this.handleComboboxClear = () => {
      this.issuerFilterValue = null;
      this.fetchSidRequests();
      this.comboboxText = '';
      this.syncFilterToLocation();
    };

    this.handleOptionSelected = (option) => {
      this.sidRequestsLandingPageState.pageNo = 1;
      this.comboboxText = option.name;
      this.comboboxOptions = [];
      this.issuerFilterValue = option.value;
      this.fetchSidRequests();
      this.syncFilterToLocation();
    };

    this.formatDates = (sidRequests) => {
      for (let item in sidRequests) {
        if (sidRequests.hasOwnProperty(item)) {
          sidRequests[item].recordDate = formatDate(
            sidRequests[item].recordDate,
            'EN'
          );
          sidRequests[item].dateCreated = formatDate(
            sidRequests[item].dateCreated,
            'EN'
          );
        }
      }
    };
  },
];

export default {
  bindings: {},
  templateUrl: `${SID_AG_BASE_URL}/views/landing-page/sid-agent-landing-page.template.html`,
  controller: Ctrl,
};
