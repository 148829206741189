import en from './localeEn';
import { GM_CONTACT_EMAIL } from '@/global/constants';

export default {
  ...en,

  label_hi: 'Goedendag',
  label_generalMeeting1: 'Algemene Vergadering',
  label_generalMeetingName: 'General meeting name',
  label_issuer: 'Uitgevende instelling',
  label_identificationNumber: 'Identificatienummer',
  label_issuer_name: 'Issuer name',
  label_type: 'Type',
  label_genMeetingDate: 'Datum Algemene Vergadering',
  label_meetingDate: 'Datum vergadering',
  label_status: 'Status',
  label_action: 'Action',
  label_advancedSearch: 'Advanced search',
  label_cet: ' CET',
  label_securityClass: 'Type securities',
  label_createGenMeeting: 'Create general meeting',
  label_editGenMeeting: 'Edit general meeting',
  label_requestSubmittedAt: 'Request submitted on',
  label_requestSubmittedBy: 'Request Submitted by',
  label_extendedIntermediaryDeadline: 'Intermediary deadline',
  label_extendedIntermediary: 'Extended Intermediary deadline',
  label_viewExtnReq: 'View extension requests',
  votecollector_list_tooltip:
    'Please click on group name(s) to enter the vote collector display names.',
  label_vc_alias_name_error:
    'Please ensure that display name in English language is entered',
  label_vc_group_success: 'Display name saved successfully for given group',
  label_vote_collector_group: 'Vote collector group',
  label_cancelExtnReq: 'Extension Request has been cancelled successfully',
  label_notAllowedExtnReq: 'Issuer does not allow (new) extension requests',
  label_new_end_date: 'New end date/time',
  label_downloadRegFormTemplate: 'Download registration form template',
  label_extReq: 'Extension request',
  label_approveExtnReq: 'Extension Request has been approved successfully',
  label_forAgainstVotingInstructions: 'For/Against Voting instruction',
  label_abstainedVotingInstructions: 'Abstained Voting instruction',
  label_rejectExtensionReq: 'Extension Request has been rejected successfully',
  label_rejectAllExtnRequests:
    'Extension Requests have been rejected successfully',
  label_approveExtReqError:
    'Please enter a valid date. Maximum 5 hours of extension time is allowed and Extension Date cannot be greater than General Meeting Date',
  label_userMaintenance: 'User maintenance',
  label_auditAndTemplate: 'Audit and template',
  label_liveMeetingOverview: 'Live meetings overview',
  label_shareHolderEmail: 'Shareholder email',
  label_dwtEvents: 'DWT Events',
  label_entity: 'Entity',
  label_publicationDate: 'Publication date',
  label_registrationDeadline: 'Registration deadline',
  label_closingDate: 'Deadline',
  label_intermediaryDeadline: 'Intermediary deadline',
  label_options: 'Opties',
  label_typeAndLocation: 'Type and location',
  label_schedule: 'Tijdschema',
  label_shareClasses: 'Soorten aandelen',
  label_agenda: 'Agendapunten',
  label_agenda_number: 'Agenda number',
  label_features: 'Features',
  label_documents: 'Documenten',
  label_inviteSharePlan: 'Invite share plan',
  label_select: '--Selecteer--',
  label_meetingType: 'Meeting type',
  label_meetingName: ' Titel vergadering',
  label_name: 'Naam',
  label_corporateWebsite: 'Corporate website',
  label_website: 'Website',
  label_location: 'Locatie',
  label_locationName: 'Naam van de locatie',
  label_locationOnTicket: 'Location on ticket',
  label_street: 'Adres',
  label_postalCode: 'Postcode',
  label_country: 'Land',
  label_proxiesList: 'Clearing custodian',
  label_selectClearingIntermediary: '--Select clearing intermediary--',
  label_timeZone: 'Time zone',
  label_ibanNumber: 'IBAN number',
  label_directDebit: 'Direct debit',
  label_convocationDate: 'Convocation date',
  label_shareholderAccessDate: 'Shareholder access date',
  label_intermediaryAccessDate: 'Intermediary access date',
  label_recordDate: 'Registratiedatum',
  label_shareholderDeadlineDate: 'Einddatum registratie',
  label_intermediaryDeadlineDate: 'Intermediary deadline date',
  label_regListReleaseDate: 'Registration list release date',
  label_noOfSecurities: 'Aantal aandelen',
  label_generalMeetingDate: 'Datum van vergadering',
  label_error_hoursAndMinutes:
    'Please enter hours from 0 to 23 and minutes from 0 to 59',
  label_prefixNumber: 'Prefix number',
  label_isin: ' ISIN',
  label_shareClassName: 'Share class name',
  label_intermediary: 'Intermediair',
  label_shareholder: 'Shareholder',
  label_shareplanholder: 'Share plan holder',
  label_search: 'Search',
  label_additionalShareClass: 'Additional share class',
  label_additionalDocs: 'Additional documents',
  label_attachDocument: 'Attach document',
  label_browse: 'Bladeren',
  label_votable: 'Votable',
  label_agendaItem: 'Agenda item',
  label_agent: 'Agent ',
  label_addNewVoteCollector: 'Add new vote collector',
  label_addVoteCollector: 'Add vote collector',
  label_noDataFound: 'No data Found',
  label_login: 'Inloggen',
  label_loginHeader: 'inloggen',
  label_forgotPwrd: 'Wachtwoord resetten',
  label_gmNotice: 'GM notice',
  label_optOutGmNotice: "Ik wil geen informatie over AvA's ontvangen ",
  label_webaPage: 'Visible on relevant web pages',
  label_attendanceCard: 'Attendance card',
  label_previous: ' Previous',
  label_next: 'Volgende',
  label_prev: 'Vorige',
  label_first: 'Eerste',
  label_last: 'Last',
  label_nl: 'NL',
  label_en: 'EN',
  label_fr: 'FR',
  label_pipe: ' | ',
  label_colonStar: ':*',
  label_colon: ':',
  label_hash: '#',
  label_comma: ',',
  label_questionMark: '?',
  label_noDataMessage: 'No data available',
  label_noDataMessageExt: 'There are no extension requests available',
  label_agents: 'Agents',
  label_issuers: 'Issuers',
  label_approve: 'Approve',
  label_reject: 'Reject',
  label_rejectAll: 'Reject all',
  label_accept: 'Accept',
  label_confirmationMessage: 'Bevestigingsbericht',
  label_areYouSure: 'Are you sure you want to approve?',
  label_areYouSureSend: 'Are you sure you want to send?',
  label_areYouSureCancel: 'Are you sure you want to cancel?',
  label_areYouSureSave: 'Are you sure you want to save?',
  label_yes: 'Ja',
  label_no: 'Nee',
  label_save: 'Opslaan',
  label_view: 'View',
  label_viewUsers: 'View users',
  label_edit: 'wijzigen',
  label_notification: 'Notification',
  label_notification_link: 'GM Notification Sheet',
  label_inviteShareplan: 'Invite share plan',
  label_add: 'Add ',
  label_city: 'Plaats',
  label_logo: 'Logo',
  label_addNew: 'Add new',
  label_admin: 'Admin',
  label_phoneNumber: 'Telefoonnummer',
  label_personalPhoneNumber: 'Personal phone number',
  label_ok: 'Ok',
  label_groupMail: 'Opt-out for emails related to General Meetings',
  label_optOutTaxReclaim: 'Opt-out for emails related to Tax reclaim events',
  label_cancel: 'Annuleren',
  label_confirm: 'Bevestigen',
  label_file: 'File',
  label_title: 'Aanhef',
  label_firstName: 'Voornaam',
  label_lastName: 'Achternaam',
  label_meetingTypeAGM: 'AGM',
  label_meetingTypeEGM: 'EGM',
  label_goBack: 'vorige',
  label_home: 'Home',
  label_generalMeeting: 'Algemene Vergaderingen',
  label_DWT: 'DWT',
  label_shareholderID: 'Shareholder ID',
  label_corporateBroking: 'Corporate Broking',
  label_btnPending: 'In behandeling',
  label_btnAccepted: 'Geaccepteerd',
  label_btnApproved: 'Goedgekeurd',
  label_btnRejected: 'Afgekeurd',
  label_btnCancelled: 'Geannuleerd',
  label_requestCreatedAt: 'Verzoeken ingediend op',
  label_nameBeneficialOwner: 'Name beneficial owner',
  label_shareholderPosition: 'Shareholder position',
  label_option: 'Option',
  label_delete: 'Delete',
  label_generalDetails: 'Algemene informatie ',
  label_registrations: 'Registrations',
  label_voteCollector: 'Entiteit die steminstructies verzamelt',
  label_locationOnCertificate: 'Naam van locatie op registratiebewijs',
  label_votingResult: 'Voting result',
  label_votingAvailable: 'Voting available',
  label_votingNotAvailable: 'Voting not available',
  label_votingItem: 'Stem item',
  label_defaultVotingOptions: '(voor, tegen, onthouding)',
  label_intermediaryWorkstation: 'Intermediary workstation',
  label_shareholderWorkstation: 'Shareholder workstation',
  label_SplitVotingRequired: 'Split voting required',
  label_disclosureOfUnderlyingBeneficialOwnersRequired:
    'Disclosure of underlying beneficial owners required',
  label_fillText:
    '* Fill out all fields in this page and click "Save" button to navigate to next section.',
  label_generalMeetingShouldBeVisible: 'General meeting should be visible',
  label_enableNewVotingResolution: 'Enable new voting resolution',
  label_agentLogoOnRegistrationTicket: 'Agent logo on registration ticket',
  label_issuerLogoOnRegistrationTicket: 'Issuer logo on registration ticket',
  label_emailAddress: 'E-mail adres',
  genDetails_label2: 'Disclosure of underlying beneficial owners required',
  label_shareClass: 'Type securities',
  label_companyName: 'Naam vennootschap',
  label_company: 'Vennootschap',
  label_voteConfirmation: 'Bevestiging steminstructie',
  label_votingInstructions: 'Steminstructies',
  label_viewVotingInstructions: 'bekijk steminstructie',
  label_viewSharePlanHolder: 'View Share plan holder',
  label_viewattendanceRequests: 'View attendance requests',
  label_voteRightsPerShare: 'Totaal aantal stukken',
  label_entitledVoteRightsShares: 'Stukken met stemrecht',
  label_shareplan: 'Share plan',
  label_votecollector: 'Entiteit die steminstructies verzamelt',
  label_securities: 'securities',
  label_resend_selected: 'Resend Selected',
  label_instruction: 'instruction',
  label_attendanceRegistration: 'Attendance registration',
  label_votingInstruction: 'Voting instruction',
  label_oldPwrd: 'Huidig wachtwoord',
  label_newPwrd: 'Nieuw wachtwoord',
  label_reenterPwrd: 'Bevestig nieuw wachtwoord',
  searchSharePlanHolder_label: 'Securities holder name/Email',
  label_resend: 'Resend',
  label_resendInviteSharePlan: 'Resend invite share plan',
  label_no_sharePlan_holders_available: 'No Share plan holders available',
  label_sharePlan_reinvite:
    'Invite share plan email will be sent to the selected share plan holders',
  label_changePwrd: 'Wachtwoord wijzigen',
  label_submit: 'Submit',
  label_pwrdRequirements: 'Wachtwoordvereisten',
  label_total: 'Total',
  label_download: 'Download',
  label_registrationListPDF: 'Registration list PDF',
  label_registrationListXLS: 'Registration list XLS',
  label_shareholdersAttendanceRegistration:
    'Shareholders requested for attendance registration',
  label_shareholdersVotingInstructions:
    'Shareholders requested for voting instructions',
  label_reinviteShareplan: 'View/Reinvite share plan holder',
  'label_%ofOutstandingVotingRights': '% of outstanding voting rights',
  label_attendanceRequest: 'Aanmelden',
  label_noAction: 'No action',
  label_registrationTicketPreview: 'Registration ticket preview',
  label_downloadRegCertificate: 'Download all registration certificates',
  label_registrationCertificates: 'Registration certificates',
  label_createNewRegistration: 'Create new registration',
  label_downloadAll: 'Download all',
  label_uppercaseLetters: 'tenminste 1 hoofdletter',
  label_lowercaseLetters: 'tenminste 1 kleine letter',
  label_numbers: 'tenminste 1 cijfer',
  label_specialCharacters:
    "tenminste 1 speciale teken ($ + , : ; = ? @ # | ' . ^ * ) ( % ! - ~ } { ] [ _ / )",
  label_8ormoreCharacters: 'minimaal 8 tot maximaal 20 tekens',
  label_email: 'E-mailadres',
  label_addOtherLang: 'Add other language',
  label_important: 'Important',
  label_registrationTicketCreated: 'Registration ticket created',
  label_emailAuditTrail: 'Email Audit Trail',
  label_auditTrail: 'Audit Trail',
  label_templates: 'Templates',
  label_uploadTemplates: 'Upload templates',
  label_sentOutMails: 'Sent out mails',
  label_reset: 'Vrijgeven',
  label_securitiesHolder: 'Securities holder',
  label_registrationNumber: 'Registratie nummer',
  label_proxyName: 'Naam gevolmachtigde',
  label_noOfShares: 'Aantal aandelen',
  label_fromDate: 'From date',
  label_toDate: 'To date',
  label_intermediaries: 'Intermediaries',
  label_emailType: 'Email type',
  label_receiverEmailAddress: 'Receiver email address',
  label_subject: 'Subject',
  label_emailDetails: 'Email details',
  label_sentTo: 'Sent to',
  label_userName: 'User name',
  label_from: 'From',
  label_to: 'To',
  label_auditTrailDetails: 'Audit Trail Details',
  label_user: 'User',
  label_platform: 'Platform',
  label_Date: 'Date',
  label_templateType: 'Template type',
  label_subbjectOrDescription: 'Subject/Description',
  label_descriptionDetails: 'Description details',
  label_template: 'Template',
  label_language: 'Language',
  label_modified: 'Modified',
  label_sender: 'Sender',
  label_documentType: 'Document type',
  label_dwtBulkUpload: 'DWT bulk upload',
  label_bulkUpload: 'Bulk upload',
  label_bulkUploadVI: 'Bulk upload voting instructions',
  label_users: 'Users',
  label_role: 'Role',
  label_event: 'Event',
  label_eventName: 'Event name',
  label_dwtEvent: 'DWT envent',
  label_votingInvitationWithLinkToMeetings:
    'Voting invitation with link to meetings',
  label_sentDate: 'Sent date',
  label_newIntermediary: 'New Intermediary',
  label_intermediaryName: 'Naam intermediair',
  label_intermediaryPhone: 'Telefoonnummer intermediari',
  label_intermediaryEmail: 'Intermediary email address',
  label_intermediaryContactName: 'Contactpersoon intermediair',
  label_intermediaryGroupName: 'Intermediary group name',
  label_bic: 'BIC',
  label_registrationForm: 'Registratieformulier',
  label_processVotingInstructions: 'Process voting instructions',
  label_receiveMeetingNotification: 'Receive meeting notification via Swift',
  label_processRegRequest: 'Process registration request',
  label_visibleInShareholderWorkstation: 'Visible in Shareholder workstation',
  label_CanProcessOnlyAssignedShareclasses:
    'Can process only assigned share classes',
  label_processCustomTemplate:
    'Process custom template for RC email to shareholder',
  label_addIssuer: 'Add Issuer',
  label_permissionsForms: 'Permissions / Registration forms',
  label_validFrom: 'Valid from',
  label_validTo: 'Valid to',
  label_openBracket: '(',
  label_closeBracket: ')',
  label_shareholderType: 'Shareholder type',
  label_selectShareholderType: '--Select Shareholder type--',
  label_initials: 'Voorletters',
  label_isAdmin: 'Is Admin',
  label_searchResults: 'Search results for',
  label_resetInstructionsSent: 'Reset instruction sent',
  label_resetPwrd: 'Reset password',
  label_checkEmail: 'Check your email',
  label_canProceedVotingInstructions: 'Can process voting instructons',
  label_addIntermediary: 'Voeg intermediair toe',
  label_addNewProxy: 'Add new proxy',
  label_thankYou: 'Bedankt',
  forgotPassSuccess_label1:
    'Het password reset verzoek is uitgevoerd. Als de opgegeven gebruikersnaam bestaat wordt er een email met instructies gestuurd.',
  label_entityName: 'Entity name',
  label_actionType: 'Action type',
  label_sentDateGMT: 'Sent date and time',
  label_screenName: 'Screen name',
  label_templateName: 'Template name',
  label_workstation: 'Workstation',
  label_templateText: 'Template text',
  label_doNotPublish: 'Do not publish',
  genMeetRegistration_label1: 'Total number of outstanding voting rights',
  genDetails_label1:
    'Include meeting documents along with registration certificate',
  agent_home_welcomeText1: `Welcome to ABN AMRO's Corporate Broking Portal, where you can facilitate your general meetings and DWT processes.`,
  agent_home_welcomeText2: 'Please find below your ',
  label_actionList: 'action list.',
  createGenMeet_fillFormText: 'Fill form in',
  label_euroclear_shares: 'Euroclear shares',
  createGenMeet_draftSaveText: 'Draft automatically saved at',
  createGenMeet_shareClass_label1:
    'Total number of outstanding securities, entitled to vote',
  createGenMeet_shareClass_label10:
    'Total number of outstanding securities for Euroclear Nederland, entitled to vote',
  createGenMeet_shareClass_label2:
    'Vote rights per share(for this share class)',
  createGenMeet_shareClass_label3: 'Total number of voting rights',
  createGenMeet_shareClass_maxARAllowed:
    'Maximum number of attendance requests allowed',
  createGenMeet_vote_by_proxy: 'Stem via proxy',
  createGenMeet_vote_by_correspondence: 'Stem per correspondentie',
  createGenMeet_shareClass_label5: 'Aanwezigheidsverzoek',
  createGenMeet_shareClass_label5_1: 'Virtuele deelname',
  createGenMeet_shareClass_label6: 'Registration list available via',
  createGenMeet_shareClass_label7: 'Vote collector workstation',
  createGenMeet_shareClass_label8: 'Authorized vote collectors',
  createGenMeet_shareClass_label9: 'Uitgevende instelling',
  votingByCorrespondence: 'Stemmen per correspondentie',
  virtualParticipation: 'Virtuele deelname',
  createGenMeet_shareClass_agent_itself: 'Agent',
  createGenMeet_agenda_label1: 'Explanation (if applicable)',
  createGenMeet_agenda_label2: 'New venture approval',
  createGenMeet_agenda_label3: 'Add agenda item',
  createGenMeet_agenda_copy: 'Copy agenda items',
  createGenMeet_agenda_copy_tooltip:
    'Copies all agenda items to any other selected language',
  createGenMeet_doc_label1: 'Attach file to',
  createGenMeet_feature_label1: 'Intermediary workstations',
  createGenMeet_feature_label2: 'Split voting allowed',
  createGenMeet_feature_rightToSpeak:
    'Attendance request with right to speak allowed',
  createGenMeet_feature_label3: 'Shareholder workstations',
  createGenMeet_feature_label5: 'Split voting allowed',
  createGenMeet_feature_label6: 'Securities account number disclosure required',
  linkedSC_feature_tooltip:
    "Enabling this feature will alert shareholders to vote/attend for the meeting which has shared share class(ISIN). The message will be displayed in shareholder work station only if the current date is with in the range of other meeting's shareholder access and deadline dates",
  linkedMeeting_attend_label: 'Om ook aanwezig te zijn voor ',
  linkedMeeting_vote_label: 'Om ook te stemmen voor ',
  label_plsClick_Yes_toContinue: 'klik dan op "Ja" om verder te gaan',
  createGenMeet_feature_linkedSC:
    'Display message to vote/attend for the share class linked meeting',
  label_registrationTicket: 'Registration ticket',
  createGenMeet_feature_label8: 'Include barcode on registration ticket',
  createGenMeet_feature_label9: 'Agent logo on attendance card',
  createGenMeet_feature_label10: 'Issuer logo on attendance card',
  createGenMeet_feature_label11:
    'Include blank power of attorney on registration ticket',
  createGenMeet_feature_label12:
    'Include meeting document along with registration certificate',
  virtualParticipation_allowed: 'Virtual participation allowed',
  only_9numbers_error: 'Please enter numeric value of length 9 characters only',
  virtual_participation_provider_meeting_id: 'Meeting ID',
  virtual_participation_provider_info1:
    '- It should be 9 numerics without any special characters. example: 127909002.',
  virtual_participation_provider_info2:
    '- Please share the meeting id and the issuer details with the virtual participation provider once the meeting is successfully created.',
  createGenMeet_InviteSP_label1: 'From address',
  label_toAddress: 'To address',
  createGenMeet_InviteSP_label2: 'Email subject',
  createGenMeet_InviteSP_label3: 'Email body',
  createGenMeet_InviteSP_label4: ' Include uploaded files',
  createGenMeet_InviteSP_label5: 'No Files uploaded',
  createGenMeet_InviteSP_label6: 'Please confirm your registration',
  createGenMeet_InviteSP_label7: 'Step 1 ',
  createGenMeet_InviteSP_label8: 'Step 2 ',
  createGenMeet_InviteSP_download: 'Download Share Plan template',
  createGenMeet_InviteSP_upload: 'Upload Share Plan data',
  createGenMeet_InviteSP_placeholder1: 'corporate.broking.finance_dummy@nl.com',
  createGenMeet_InviteSP_placeholder2: 'Please confirm your registration',
  createGenMeet_InviteSP_tooltip1:
    'Template needs to be filled in with share plan data by issuer.',
  createGenMeet_InviteSP_tooltip2:
    'Template downloaded earlier needs to be uploaded with share plan data.',
  userMaint_voteCollector_label: 'Entiteit die steminstructies verzamelt',
  userMaint_intermediary_label: 'Intermediair',
  userMaint_proxy_label: 'Proxy Intermediary',
  userMaint_groupDetails_label: 'Group details',
  userMaint_userAccounts_label: 'User accounts',
  userMaint_groupEmailAddress_label: 'E-mailadres groep',
  userMaint_bankIdentifierCode_label: 'Bank Identifier Code',
  userMaint_bankAccountNumber_label: 'Bank account number',
  userMaint_usersList_label: 'Users list',
  userMaint_officePhone_label: 'Office phone',
  userMaint_commentsForRejection_label: 'Comments for rejection',
  userMaint_issuer_warning_alreadyvc_label:
    'The provided e-mail is already registered as Vote Collector. Are you sure you want to add it as Issuer?',
  addUser_userAccounts_label: 'User account',
  forgotPass_label1: 'Aanvraag nieuw wachtwoord verzonden',
  forgotPass_label2:
    'Have you lost your password? You can request a new password. We will e-mail an activation link, and you can then enter a new password.',
  viewAttendance_label1:
    'Share holder name/Registration number/Securities account number',
  viewAttendance_label2: 'Shareholders registered list',
  viewVotingInstructions_label1: 'Securities holders registered list',
  item0: 'Home',
  item1: 'Algemene Vergaderingen',
  item3: 'DWT',
  item4: 'Shareholder ID',
  item5: 'Corporate Broking',
  item2: 'Mijn gegevens',
  button0: 'Draft',
  button1: 'Pending',
  button2: 'To be published',
  button3: 'Live',
  button4: 'Completed',
  button5: 'Geannuleerd',
  extnbtn0: 'Pending',
  extnbtn1: 'Accepted',
  extnbtn2: 'Rejected',
  label_votingConfirmation: 'Voting confirmation',
  label_votingInstructionReport: 'Voting instruction reports',
  label_downloadPDF: 'Download PDF',
  label_downloadExcel: 'Download Excel',
  label_summary: 'Summary',
  label_myVotes: 'Mijn uitgebrachte stemmen/registraties',
  label_Details: 'Details',
  label_inFavor: 'In favor',
  label_against: 'Tegen',
  label_Abstained: 'Onthouden',
  label_addAttachments: 'Add attachments',
  label_addText: 'Add text',
  label_send: 'Versturen',
  adminAgent_label_profile: 'Profiel',
  adminAgent_label_changePwrd: 'Wachtwoord wijzigen',
  adminAgent_label_initials: 'Voorletters',
  adminAgent_label_middleName: 'Voornamen',
  adminAgent_label_phoneNumber: 'Telefoonnummer',
  adminAgent_label_pwrdRequirements: 'Wachtwoordvereisten',
  adminAgent_label_confirmation: 'Confirmation message',
  adminAgent_label_wantToSave: 'Are you sure you want to save?',
  gmNotification_label_1: 'Notification overview',
  gmNotification_label_2: 'Attachments to notification',
  gmNotification_label_3: 'Customise message',
  gmNotification_label_4: 'Meeting notice',
  gmNotification_label_5: 'Recipient list',
  label_recipientAddress: 'Recipient address',
  gmNotification_custMsg1: 'Available means of participation',
  gmNotification_custMsg2:
    'Additional information for notification of participation',
  gmNotification_custMsg3: 'Narrative',
  gmNotification_custMsg4:
    'Other requirements for participation (if applicable)',
  voting_result: 'Voting results',
  select_recipient: '--Select recipient--',
  select_shareClass: '--Selecteer soort aandelen--',
  label_selectProxyType: '--Selecteer type gevolmachtigde--',
  label_selectCountry: '--Selecteer land--',
  label_selectUserType: '--Selecteer type gebruiker--',
  label_selectTitle: '--Selecteer aanhef--',
  label_selectIntermediary: '--Selecteer intermediair--',
  gmGeneralDetails_label: 'Shareholder',
  label_serialNo: 'Sl No.',
  label_error_lastNameReq: 'Last name is required.',
  label_error_lastNameWrong: 'Naam is niet juist',
  label_error_officeNumReq: 'Office phone number is required.',
  label_error_officeNumWrong: 'Telefoonnummer kantoor verkeerd',
  label_error_phoneNumReq: 'Phone number is required',
  label_error_persoNumWrong: 'Personal phone number is wrong',
  label_error_phoneNumWrong: 'Telefoonnummer te kort of ongeldig formaat',
  label_error_emailReq: 'E-mailadres is verplicht',
  label_error_emailWrong:
    'E-mailadres is onvolledig of voldoet niet aan het format',
  label_error_name: 'Naam is verplicht',
  label_error_nameWrong: 'Naam is niet juist',
  label_error_postWrong: 'Postcode is niet juist',
  label_error_cityReq: 'Plaats is verplicht',
  label_error_cityWrong: 'Plaats is niet juist',
  label_error_noOfSharesReq: 'Aantal aandelen is verplicht',
  label_error_noOfSharesWrong: 'Aantal aandelen is niet juist',
  label_error_selectedQuantityNoOfSharesReq: 'Deelbelang is verplicht',
  label_error_selectedQuantityNoOfSharesWrong: 'Deelbelang is niet juist',
  label_error_addressWrong: 'Adres is niet juist',
  label_error_securityAccNoReq: 'Effectenrekeningnummer is verplicht',
  label_error_securityAccNoWrong:
    'Bij het invullen van een beleggings-/effectenrekeningnummer, kunt u alleen cijfers en letters gebruiken',
  label_error_countryReq: 'Country is required.',
  label_error_countryWrong: 'Country is wrong.',
  label_error_bicReq: 'Bank Identifier Code is required.',
  label_error_bicWrong: 'Bank Identifier Code is niet juist',
  label_error_legalIndentifierWrong: 'Legal entity identifier is wrong.',
  label_error_repNameWrong: 'Representative name is wrong.',
  label_error_proxyNameWrong: 'Proxy name is wrong.',
  label_error_proxyNameReq: 'Naam gevolmachtigde is verplicht',
  label_poaForm: 'PoA form',
  linkIntermediary_header1:
    'Intermediaries for Institutional Shareholder Services (ISS) Europe',
  linkIntermediary_header2:
    'These are the intermediaries for which Institutional Shareholder Services (ISS) Europe can process regulations',
  linkIntermediary_button1: 'Link to Intermediary group',
  addPermission_header: 'Permissions/Registration form',
  label_error_middleNameWrong: 'Middle name is wrong.',
  label_error_firstNameWrong: 'First name is wrong.',
  label_error_initialsWrong: 'Voorletters zijn niet juist',
  label_error_titleWrong: 'Title is wrong.',
  label_error_titleReq: 'Title is required.',
  voteCollectorbutton0: 'Before deadline',
  voteCollectorbutton1: 'After deadline',
  voteCollectorbutton2: 'Completed',
  label_userType: 'User type',
  label_address: 'Adres',
  label_dob: 'Geboortedatum',
  label_securityAccountNumber: 'Beleggingsrekening',
  label_numberOfShares: 'Aantal aandelen',
  label_attendByProxy: 'Volmacht geven',
  label_createNewProxy: 'Nieuwe gevolmachtigde',
  label_editProxy: 'Wijzigen gevolmachtigde',
  label_selectProxy: 'Selecteer gevolmachtigde',
  label_delegateToSaat: 'Delegeren aan SAAT',
  label_proxyType: 'Type gevolmachtigde',
  label_proxyId: 'Proxy id',
  label_none: 'Geen',
  label_vote: 'Via steminstructie',
  label_register: 'Registreren nieuwe gebruiker',
  label_allFor: 'Alles voor',
  label_splitVotes: 'Split votes',
  label_entireHolding: 'Volledig belang',
  label_selectedQuantity: 'Selected quantity',
  label_partialHolding: 'Deelbelang',
  label_for: 'Voor',
  label_enterNumberOfShares: 'Aantal aandelen',
  label_allAgainst: 'Alles tegen',
  label_allAbstained: 'Alles onthouden',
  label_attend: 'Bijwonen van vergadering',
  label_downloadCertificate: 'Download certificate',
  label_upComingMeetings: 'Aankomende Algemene Vergaderingen',
  label_representativeName: 'Naam vertegenwoordiger',
  label_legalEntityIdentifier: 'Legal Entity Identifier',
  label_termsAndConditions: 'Algemene voorwaarden',
  label_terms: 'Ik heb de algemene voorwaarden gelezen en ga hiermee akkoord',
  label_termsUse: 'Gebruiksvoorwaarden',
  label_numberOfSecurities: 'Number of securities',
  label_attendanceInfo:
    'The following registrations will be automatically cancelled when attendance request is submitted',
  label_votingInfo:
    'The following registrations will be automatically cancelled when voting instruction is submitted',
  label_termsSentence: 'I have read and accepted the ',
  label_belgiumTermsConditions1:
    'Ik/wij bevestig/bevestigen kennis te hebben genomen van de ',
  label_belgiumTermsConditions2:
    ', de inhoud ervan te begrijpen en te aanvaarden en een kopie ervan te hebben bewaard, geprint of naar mezelf/onszelf te hebben verzonden.',
  label_votingViaIMWorkStation:
    'Voting instructions via the intermediary workstation',
  label_votingViaSHWorkStation:
    'Voting instructions via the shareholder workstation',
  label_requestRegistrationViaSHWorkStation:
    'Request a registration ticket via the shareholder workstation',
  label_groupName: 'Group name',
  label_groupCountry: 'Group country',
  label_groupRegisteredOffice: 'Group registered office',
  label_internationalBankAccountNumber: 'International bank account number',
  label_taxDeclarationNumber: 'Tax declaration number',
  label_uploadedDocuments: 'Uploaded documents',
  label_groupStatus: 'Group status',
  label_groupCity: 'Group city',
  notification_text:
    'Beste aandeelhouder, om u zo goed mogelijk van dienst te kunnen zijn, vragen wij u om uw gegevens te controleren.',
  label_companiesOfferingGm:
    'Vennootschappen die gebruikmaken van het aandeelhoudersportaal van ABN AMRO zijn:',
  label_seeMore: 'Meer informatie',
  label_knowMore: 'Know more',
  label_error_groupName: 'Group name is wrong',
  label_error_groupNameReq: 'Group name is required',
  label_pleaseFillThisField: 'Please fill this field',
  label_proxyProvided: 'Proxy provided',
  label_proxyProfile: 'Proxy profile',
  label_noDataIssuerMessage: 'No data available for issuer',
  label_feedback: 'Feedback',
  label_extensionRequestFor: 'Extension Request for',
  label_shareHolderName: 'Shareholder name',
  label_extendDeadlineTillDate: 'Extend deadline till date',
  label_reasonForDelay: 'Reason for delay',
  label_reasonForReject: 'Reason for rejection',
  label_time: 'Time',
  label_createExtnReq: 'Create extension request',
  label_tillDateReq: 'Extend deadline till date is required',
  label_shareholderNameReq: 'Shareholder name is required',
  label_shareholderPosReq: 'Shareholder position is required',
  label_shareholderPosError: 'Shareholder position is wrong',
  label_shareholderPosIncorrect: 'Shareholder position can be 1 to 999999999',
  label_attachment: 'Attachment',
  label_importFile: 'Import file',
  label_downloadStandardFile: 'Download standard file',
  label_bulkUploadAttendanceRequest: 'Bulk upload attendance request',
  label_MR: 'Mr.',
  label_MS: 'Ms.',
  label_voting_instruction: 'Steminstructies',
  label_attendance_requests: 'Registratie verzoek',
  label_invite_share_plan: 'Invite share plan',
  label_shareholder_email: 'Shareholder email',
  label_intermediary_email: 'Intermediary email',
  label_notification_overview: 'Notification Overview',
  label_create_extension_request: 'Create Extension Request',
  label_bulk_upload_attendance_requests: 'Bulk upload attendance request',
  label_bulk_upload_voting_instructions: 'Bulk upload voting instructions',
  label_view_attendance_requests: 'View attendance requests',
  label_view_agent_group: 'View agent group',
  label_edit_agent_group: 'Edit agent group',
  label_edit_issuer_details: 'Edit issuer details',
  label_edit_intermediary_details: 'Edit intermediary details',
  label_edit_proxy_intermediary_details: 'Edit proxy intermediary details',
  label_edit_votecollector_details: 'Edit vote collector details',
  label_agent_group: 'Agent users',
  label_view_agent_group_users: 'View agent users',
  label_add_user: 'Add user',
  label_add_issuer: 'Add issuer',
  label_add_intermediary: 'Voeg intermediair toe',
  label_add_proxy_user: 'Add proxy user',
  label_add_vote_collector: 'Add vote collector',
  label_view_agent_details: 'View agent details',
  label_edit_agent_details: 'Edit user details',
  label_edit_vote_collector_details: 'Edit vote collector details',
  label_view_issuer_group: 'Issuer groups',
  label_view_vote_collector_group: 'Vote collector groups',
  label_view_intermediary_group: 'Intermediary groups',
  label_view_proxy_intermediary_group: 'Proxy intermediary groups',
  label_add_issuer_group: 'Add issuer group',
  label_add_vote_collector_group: 'Add vote collector group',
  label_add_intermediary_group: 'Add intermediary group',
  label_add_proxy_intermediary_group: 'Add proxy intermediary group',
  label_view_intermediary_user: 'View intermediary users',
  label_view_proxy_intermediary_user: 'View proxy intermediary users',
  label_view_issuer_user: 'View issuer users',
  label_view_vote_collector_user: 'View vote collector users',
  label_view_issuer_details: 'View issuer details',
  label_view_intermediary_details: 'View intermediary details',
  label_view_proxy_intermediary_details: 'View proxy intermediary details',
  label_view_vote_collector_details: 'View vote collector details',
  label_add_permissions: 'Add permissions',
  label_link_intermediaries: 'Linked intermediaries',
  label_link_intermediary: 'Link to Intermediary',
  label_linkedIntMessage:
    'If needed intermediary is not listed, please contact Agent to onboard in the system.',
  label_linkedIntError:
    'The PoA for the selected intermediary has expired or is not yet valid, please contact Agent.',
  label_page_title: 'Corporate Broking Portal',
  label_logoUpdate: 'Update logo',
  label_extededTill: 'Intermediary deadline extended till',
  label_reasonForExtension: 'Reason for extension',
  label_privacy_policy: 'Privacybeleid en cookies',
  label_privacy: 'Privacy',
  label_cookies: 'Cookies',
  label_contact_details: 'Contactgegevens',
  label_numberOfSharesRequired: 'Aantal aandelen is verplicht',
  label_numberOfSharesWrong:
    'Het aantal aandelen moet worden weergegeven in cijfers en mag niet beginnen met nul',
  label_addProxy: '+ Add Proxy',
  label_mandatoryFieldError: 'Verplicht velden invullen aub',
  label_textRequired: 'Text is required',
  label_pwrdReq: 'Wachtwoord invoeren aub',
  newPwd_oldPwdError:
    'Nieuwe wachtwoord mag niet hetzelde zijn als oude wachtwoord',
  checkPattern_Error: 'Graag formaat nakijken',
  label_noProxyAvailable: 'No proxy available',
  label_close: 'Sluiten',
  label_feedbackType: 'Type feedback:',
  label_feedbackComment: 'Opmerkingen',
  label_feedback_question: 'Vraag',
  label_feedback_opinion: 'Opmerking',
  label_feedback_comment_required: 'Feedback comment is required.',
  label_feedback_comment_maxlength: 'Max length is 4000 characters.',
  label_reason_comment_required: 'Reason comment is required.',
  label_reason_comment_maxlength: 'Max length is 2000 characters.',
  label_phone: 'Telefoon',
  label_show_details: 'Details tonen',
  label_hide_details: 'Details verbergen',
  thankYou_msg: 'Your vote is important.Thank you for your voting instruction.',
  thankYou_msg1:
    'If you wish to change your vote, you can do this upto shareholder deadline date of the general meeting.',
  thankYou_msgButton1: 'Vote for other Share classes for this GM',
  thankYou_msgButton2: 'Browse and vote for GMs from other Issuers',
  notificationModal_header: 'Save Customised Message',
  notificationModal_body:
    'Do you want to retain the modified text for future use? (applies to all Issuers)',
  notificationModal_rightButton: 'No,for this instance only',
  notification_successMsg1: 'Message saved successfully for future use',
  notification_successMsg2: 'Message saved successfully for only this instance',
  notification_successMsg3: 'Notification sent successfully',
  label_shareHolderCreated:
    'Uw registratie is afgerond. Controleer uw email om uw account te activeren.',
  label_shareHolderCreated_header: 'Registratie afgerond',
  label_shareholderCreationError:
    'Some error has occured while creating Shareholder, Please contact system administration',
  label_agendaDeleted: 'Agenda deleted successfully.',
  label_agendaItemsCopied:
    'Agenda items successfully copied to other language(s).',
  label_documentDeleted: 'Document deleted successfully.',
  label_shareClassDeleted: 'Share class deleted successfully.',
  label_messageSaved: 'Meeting saved successfully.',
  label_createMeetingError:
    'Something went wrong while creating meeting. Please try after sometime',
  label_fillMandatoryFields:
    'Please fill mandatory fields to save this section',
  label_fillValidInput:
    'Please fill mandatory fields with valid input and try again.',
  label_attachmentRequired:
    'Attachment cannot be blank, Please upload a pdf file',
  label_documentLessThan5mb: 'Document should be less than 5mb',
  label_meetingSentToIssuer: 'Meeting sent to Issuer',
  label_meetingApproved: 'Meeting approved successfully',
  label_agendaRequired: 'Please fill agenda section',
  label_docRequired: 'Please fill document section.',
  label_shareClassRequired: 'Please fill share class section.',
  label_create_extn_error:
    'Extend deadline till date must be greater than intermediary deadline date and less tha general meeting date.',
  label_modalConfirmAction: 'Confirm action',
  label_modalVotingInsCreatErr: 'Voting is not available',
  label_modalVotingInsCreatErrMsg:
    'Voting instruction cannot be created for this General Meeting as the intermediary deadline date is already crossed',
  label_modalBulkUploadCreatErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as voting instruction creation is not enabled',
  label_modalBulkUploadAttendCreatErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as attendance request creation is not enabled',
  label_modalShareVotingInsCreatErrMsg:
    'Het is niet mogelijk om steminstructies in te dienen voor deze vergadering',
  label_modalShareAttenReqCreatErrMsg:
    'Het is niet mogelijk om u aan te melden voor deze vergadering',
  label_modalAttReqCreatErr: 'Het is niet mogelijk om u aan te melden',
  label_modalAttReqCreatErrMsg:
    'You cannot create attendance request for this General Meeting as the intermediary deadline date is already crossed',
  label_modalVirtualReqCreateErr: 'Cannot create virtual participation',
  label_modalVirtualReqNoEntitlements:
    'Not entitlements found for this meeting',
  label_modalVirtualReqErrMsg:
    'You cannot update virtual participation request for this General Meeting since the intermediary deadline date is already expired',
  label_modalRtsCreatErrMsg:
    'You cannot create/edit right to speak request for this General Meeting since the intermediary deadline date is already expired',
  label_modalRtsCancelErrMsg:
    'You cannot cancel right to speak request for this General Meeting since the intermediary deadline date is already expired',
  label_modalDelete: 'Delete message',
  label_modalConfirmDelTemplate:
    'Are you sure you want to delete this template?',
  label_modalUpdateTemp: 'Update template',
  label_modalConfirmUpdateTemplate:
    'Are you sure you want to update this template?',
  label_shareHolderRegistration: 'Registratie aandeelhouder',
  label_registrationCertificate: 'Registratiecertificaat',
  label_request_type: 'Type verzoek',
  lable_newReenterError: 'Wachtwoord niet gelijk',
  issuer_groupUpdated_success: 'Issuer group details updated successfully',
  label_votingInsCreatErr: 'Informatie',
  label_bulkUploadCreatErr: 'Upload error',
  error_shareClass_not_created:
    'Something went wrong while saving share class. Please try after sometime.',
  error_shareClass_not_created_invalid_data:
    'Please fill mandatory fields with valid information',
  error_shareClass_not_created_invalid_participation_data:
      'Invalid Participation method selection, Please fill valid information',
  only_pdf_allowed: 'Invalid document(upload valid pdf file).',
  agenda_file_sizeMsg: 'This agenda file size is ',
  file_sizeMsg: ' MB. Please upload a file less than 5 MB.',
  document_file_sizeMsg: 'This document size is ',
  error_send_to_issuer:
    'There are errors. Please verify if all the sections are filled with valid information',
  newPwd_requiredError: 'New password required',
  reEnter_requiredError: 'Re-enter password required',
  numAlpha_error: 'Only alphabets and numbers are allowed',
  password_renterMatchError:
    'Both New password and Re enter password must be same',
  label_bulkUploadBO: 'Upload BO Details',
  label_registration_no: 'Registratienummer',
  label__error_registrationNoWrong: 'Registration Number is wrong',
  label__error_registrationNoReq: 'Registration Number is required',
  deadLineVoteModal_header: 'Voting is not available',
  deadLineModal_body:
    'U kunt niet meer registreren voor deze vergadering aangezien de deadline gepasseerd is.',
  deadLineModalVI_body:
    'U kunt geen steminstructies meer indienen voor deze vergadering aangezien de deadline gepasseerd is.',
  deadLineAttendModal_header: 'Attendance request not available',
  lable_nonVotableItem: 'Non-votable item',
  share_class_error_checkbox_not_selected:
    'At least one option among Voting instructions or Request an attendance card must be selected.',
  document_checkbox_not_selected:
    'Please check atleast one checkbox - Attach the file to',
  noOfShares_error1:
    'Split votes should not be greater than Total Number of Shares',
  noOfShares_error2:
    'Sum of Split votes should be equal to Total Number of Shares',
  noOfShares_error3:
    'Partial quantity cannot be greater than Total No of shares',
  votingInstruction_error_msg: 'Voor alle agenda puntenstemmen aub',
  'intermediary-success-msg': 'Intermediary request created successfully',
  reject_meetingErrorr:
    'Something went wrong while rejecting meeting. Please try after sometime',
  approve_meetingErrorr:
    'Something went wrong while approving meeting. Please try after sometime',
  reject_meeting: 'Meeting rejected successfully.',
  approve_meeting: 'Meeting approved successfully.',
  label_loginOrRegister: 'Login or Register',
  label_downloadAttachment: 'Download attachment',
  label_selectActionType: '--Select action type--',
  label_fields: 'Fields',
  label_beforeValue: 'Before value',
  label_afterValue: 'After value',
  label_forWord: 'Voor',
  label_selectTemplateType: '--Select template type--',
  label_selectTemplateName: '--Select template Name--',
  label_selectWorkstation: '--Select Workstation--',
  label_selectIssuer: '--Select issuer--',
  label_selectScreenName: '--Select screen name--',
  label_saveSuccessfullyStatus: 'Invite share plan sent successfully',
  label_errorImgFile: 'Please upload an image file.',
  label_errorFileSize: 'Please upload a file less than 250KB.',
  pwdChanged_successMsg: 'Password changed successfully',
  extnReq_cannotCreate: 'Extension request cannot be created for this meeting',
  vec_initiationMsg:
    'Processing confirmation, an e-mail will be sent when finished',
  selectDate_error: 'Please select date fields',
  fileSize_1mbError: 'Please upload a pdf file (Max Size 1 MB)',
  selectToDate_error: 'Please select To date which is greater than From date',
  selectRegForm_error: 'Please select Registration Form',
  label_areYouSureModal: 'Are you sure you want to ',
  label_thisRecord: ' this record?',
  label_shareHolder_registered: 'Shareholders registered by ',
  label_for_shareholder: 'for',
  label_toggle: 'Toggle navigation',
  uploadFile_error: 'Please upload a file.',
  fileSizeIs_error: 'This file size is: ',
  confrim_delink: 'Confirm de-link.',
  delink_confirmMsg: 'Are you sure you want to de-link this intermediary?',
  label_thisGroup: ' this group?',
  label_thisUser: ' this user?',
  label_enterUserNameToPasswordReset:
    'Vul uw gebruikersnaam in om een nieuw wachtwoord aan te vragen.',
  label_enterValidCredentials:
    'Onbekende combinatie van gebruikersnaam en wachtwoord',
  label_emailEmptyError: 'Please fill in email body',
  label_acceptExtnReqConfirm:
    'This request was rejected before, are you sure you want to approve it?',
  label_rejectExtnReqConfirm:
    'This request was approved before, are you sure you want to reject it?',
  label_acceptExtnReq:
    'This request was rejected before, are you sure you want to approve it?',
  label_rejectExtnReq:
    'This request was approved before, are you sure you want to reject it?',
  label_viewEditExtnReq: 'View/Edit Extension request',
  label_section_error: 'There are errors for ',
  label_section_language: 'language(s)',
  areSureDelete_modal: 'Are you sure you want to delete this Meeting?',
  areSureCancel_modal: 'Are you sure you want to cancel this Meeting?',
  areSureReject_modal: 'Are you sure you want to reject this Meeting?',
  createGenMeet_IntermediaryTooltip:
    'Select an intermediary,ADR or transfer agent which is only responsible to process voting / registration to attend instructions for this share class. Please select all intermediaries if this is not applicable.',
  issuer_name_req: 'Issuer name is required.',
  fill_issuer: 'Please fill out Issuer name.',
  meeting_type_req: 'Meeting type is required.',
  fill_meetingType: 'Please fill out meeting type.',
  meeting_name_req: 'Meeting name is required.',
  invalid_meetingName: 'Meeting name field accepts alphabets and numbers.',
  fill_meetingName: 'Please fill out meeting name.',
  website_req: 'Corporate website is required.',
  website_wrong: 'Corporate website is wrong.',
  fill_website: 'Please fill out corporate website.',
  eng: 'English',
  dut: 'Dutch',
  fre: 'French',
  ger: 'German',
  esp: 'Spanish',
  location_req: 'Location name is required.',
  location_wrong: 'Location name is wrong.',
  fill_location: 'Please fill out location name.',
  loc_tckt_req: 'Location on ticket is required.',
  loc_tckt_wrong: 'Location on ticket is wrong.',
  fill_loc_tckt: 'Please fill out location on ticket.',
  street_req: 'Street is required.',
  fill_street: 'Please fill out street.',
  postal_req: 'Postal code is required.',
  postal_wrong: 'Postcode is niet juist',
  fill_postal: 'Please fill out postal code.',
  city_req: 'Plaats is verplicht',
  fill_city: 'Please fill out city.',
  country_req: 'Country name is required.',
  country_fill: 'Please fill out country name.',
  label_selectProxyName: '--Select proxy name--',
  createGenMeet_TimePickerTooltip:
    'With the cursor in hour or minute fields, you can use up/down arrow keys to increase/decrease the time.',
  label_callIntermediary: 'Bel intermediair',
  label_lei: 'LEI',
  label_error_leiWrong: 'LEI is wrong',
  uploadPositionStatementFromIntermediary:
    'Upload positieoverzicht van intermediair',
  intermediaryAcceptVoteCheckbox1:
    'We hereby confirm that we have verified the identity of the aforementioned Securities Holder, and represent and warrant his true identity.',
  intermediaryAcceptVoteCheckbox2:
    'We hereby confirm that the unique identification number is the same as the number provided to us by the aforementioned Securities Holder.',
  intermediaryAcceptVoteCheckbox3:
    'We hereby confirm that the aforementioned Securities Holder holds the mentioned Securities of the Issuing Institution at the record date.',
  intermediaryAcceptVoteCheckbox4:
    'Furthermore, we hereby confirm that no prior voting instructions have been submitted by the aforementioned Securities Holder in connection with this General Meeting and that we nor the aforementioned Securities Holder will submit voting instructions other than those being submitted via the Website.',
  intermediaryAcceptVoteCheckbox5:
    'We acknowledge that we have read, understood and consented to the ',
  intermediaryAcceptVoteCheckbox6:
    ', as well as having saved, printed or sent ourselves a copy thereof.',
  label_viewMore: 'Toon meer',
  label_addressReq: 'Adres is verplicht',
  label_initialsReq: 'Voorletters zijn verplicht',
  bulkUpload_processMsg:
    'File is being processed. Please wait while processing the request.',
  bulkUpload_successMsg: 'File processed successfully.',
  bulkUpload_errorMsg: 'There are some errors: ',
  label_sessionLoggedOut:
    'You have not used the portal for 20 minutes and have been logged out automatically. Please click outside this message window to dismiss it.',
  label_sessionWarning:
    'Session is about to expire in 5 minutes. Please respond to avoid logging out',
  label_warning: 'Warning',
  label_gmActionList: 'General Meetings – Action List',
  label_dwtActionList: 'Tax Reclaim – Action List',
  label_decline: 'Decline',
  areSureAccept_modal: 'Are you sure you want to accept this Meeting?',
  areSureDecline_modal:
    'Are you sure you want to decline the role of vote collector for this meeting?',
  label_acceptedVc: 'accepted',
  label_declinedVc: 'declined',
  label_youHave: 'You have ',
  label_roleOfVC: ' the role of vote collector for meeting ',
  label_ofIssuer: ' of Issuer ',
  label_youCanAttend: 'You can vote/attend from ',
  label_dealineDateError: 'You cannot vote for this event – deadline date was ',
  label_registerText: 'Heeft u nog geen gebruikersaccount?',
  label_createAccountButton: 'Maak een nieuw account aan',
  areSureAccept_modalVc:
    'Are you sure you want to accept the role of vote collector for this meeting?',
  label_requestNumber: 'Request#',
  label_groupAddSuccess: 'New group added successfully',
  label_groupEditSuccess: 'User group updated successfully',
  label_on: 'op',
  label_uploadPosStatement_fromInt:
    'Upload Position statement from Intermediary',
  label_logout: 'Log out',
  label_intPhoneNumber: 'Intermediary phone number',
  phoneNumber_error: 'Het telefoonnummer is niet geldig',
  confirmFor_sendToIssuer:
    'Are you sure you want to send this meeting to issuer?',
  confirmUser_editDetails: 'Are you sure you want to edit user details?',
  confirmGroup_editDetails: 'Are you sure you want to edit this group details?',
  confirmApprove_Gm:
    'Please confirm that you have checked all information and the Registration ticked preview (on the Registrations tab) for this meeting.',
  confirmApprove_GmOtherLang:
    'Please confirm that you have checked all information and the Registration ticked preview (on the Registrations tab) for this meeting in all available languages.',
  pdfFile_formatError: 'Please upload document in PDF file format',
  label_createNewRequest: 'Create new request',
  label_cancelRequestBodyModal:
    'Weet u zeker dat u dit verzoek wilt annuleren?',
  label_cancelRequestHeaderModal: 'Bevestig annuleringsverzoek',
  label_viCancellationText1:
    'U wilt uw steminstructie annuleren. Zodra u dit hebt bevestigd, dient u contact op te nemen met uw intermediair om de annulering te verwerken.',
  label_intermediaryDepartment: 'Intermediary Department',
  label_loginId: 'E-mailadres',
  label_password: 'Wachtwoord',
  label_searchByIssuer: 'Zoek op uitgevende instelling',
  label_DobError: 'Date of birth should not be future date',
  label_registerButton: 'Registreer',
  label_noOfSharesError: 'Please enter number of shares.',
  label_viewIssuers: 'View issuers',
  label_viewIntermediaries: 'View intermediaries',
  label_viewProxies: 'View proxies',
  label_viewVCs: 'View vote collectors',
  label_viewAgentUser: 'View agent user',
  label_viewIntermediaryUser: 'View intermediary user',
  label_viewProxyUser: 'View proxy intermediary user',
  label_viewIssuerUser: 'View issuer user',
  label_viewVCUser: 'View vote collector user',
  label_addAgentUser: 'Add agent user',
  label_addIntermediaryUser: 'Add intermediary user',
  label_addProxyUser: 'Add proxy intermediary user',
  label_addIssuerUser: 'Add issuer user',
  label_addVCUser: 'Add vote collector user',
  label_confirmVIRequest_header: 'Bevestig steminstructie',
  label_confirmVI_registration_header: 'Bevestig registratie',
  areYouSure_confrimVI_message:
    'Are you sure you want to confirm voting instruction request?',
  label_confirmAttendRequest_header: 'Bevestig registratie',
  areYouSure_confrimAttend_message:
    'Are you sure you want to confirm attendance request?',
  callIntermediary_SorryModalbody1:
    'Uw intermediair heeft zijn contactgegevens nog niet aan ons doorgegeven. Wilt u contact opnemen met ABN AMRO Corporate Broking via ',
  callIntermediary_SorryModalbody2:
    ' of corporate.broking@nl.abnamro.com zodat wij u in contact kunnen brengen met de juiste afdeling van uw intermediair.',
  label_callIntermediary_VI_registration:
    'Bedankt voor uw steminstructie. U dient zich nu nog te registreren via uw intermediair. ',
  label_callIntermediary_AR_registration:
    'U heeft u geregistreerd om de vergadering bij te wonen, deze registratie moet nog door uw Intermediair goedgekeurd worden.',
  label_ask_provideIdentification_VI:
    'Neem derhalve contact op met uw intermediair en bevestig het volgende identificatienummer:',
  label_sh_vi_modalBody2:
    'Het kan zo zijn dat uw intermediair niet bekend is met dit nummer. In een dergelijk geval kan uw intermediair contact opnemen met ABN AMRO (AVA@nl.abnamro.com).',
  label_sh_vi_modalBody3:
    'Mocht u nog verdere vragen hebben, schroom dan niet om contact op te nemen met ABN AMRO (corporate.broking@nl.abnamro.com)',
  label_toRegister_callIntermediary:
    'Bel uw intermediair om uw steminstructie te bevestigen.',
  label_ask_provideIdentification:
    'U wordt dan gevraagd naar uw identificatie nummer:    ',
  label_contactInfo: 'Contactgegevens',
  label_telephoneNumber: 'Telefoonnummer',
  label_deptPhoneNumber: 'Telefoonnummer',
  lable_onlyNumbers_error: 'Please enter number of shares',
  label_createNewAR: 'Aanmelden voor vergadering',
  label_createNewVI: 'Steminstructies indienen',
  label_editVotingIns: 'Bekijk steminstructie',
  label_createPE: 'Create PE',
  label_bulkUploadQrf: 'Bulk QRF',
  label_dwt_notification_link: 'DWT notification link',
  label_recipient: 'Recipient',
  label_downloadQRFBulkTemplate: 'DWT QRF Bulk upload template',
  label_bulkUploadQRF: 'Bulk upload QRF claim',
  label_request_date_time: 'Request date/time',
  label_requested_new_end: 'Requested new end date/time',
  label_QRF_PE: 'QRF / PE',
  label_current_QRF_PE_End_date: 'Current QRF/PE End date',
  label_reason_for_extension_request: 'Reason for extension request',
  label_document_download: 'Document download',
  label_downloadclaimoverview: 'Download Claims Overview',
  label_cc: 'cc',
  label_vebDefault: 'VEB[Standaard]',
  label_SelectSecurityClass: '--Selecteer soort aandelen--',
  label_voteAttendEnd:
    'Het is niet meer mogelijk om steminstructies in te dienen of u aan te melden voor deze vergadering',
  label_voteAttendStart:
    'Het is nog niet mogelijk om steminstructies in te dienen of u aan te melden voor deze vergadering',
  confirm_proxyLinking: 'Are you sure you want to link this intermediary?',
  label_modalAttReqActionErrMsg:
    'You cannot accept/reject requests for this General Meeting as the intermediary deadline date is already crossed',
  max4000_characters_error: 'Maximum 4000 characters are allowed.',
  label_allSectionNotFilled:
    'Please fill all the sections with valid input and proceed.',
  label_terms_IN_line1:
    'We hereby confirm that we have verified the identity of the aforementioned shareholder and warrant his true identity.',
  label_terms_IN_line2:
    'We hereby confirm that the aforementioned shareholder is in possession of the mentioned securities of the Company at the record date. Should the Company not use a record date, then we hereby warrant that the aforementioned shareholder is in possession of the mentioned securities of the Company at the General Meeting date.',
  label_terms_IN_line3:
    "In addition, we hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned shareholder as registered for this General Meeting, with right of substitution, to represent, act and vote on the shareholder's behalf, in accordance with the shareholder's instructions,  at this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Company, the civil law notary or any other person who is entrusted with the registration and/or the proxy voting procedure for this General Meeting.",
  label_terms_IN_line4:
    'Furthermore, we hereby confirm that no prior voting instructions have been submitted or will be submitted by the aforementioned shareholder in connection with this General Meeting and that we nor the aforementioned shareholder will submit voting instructions other than those being submitted via this portal on the Website.',
  label_scroll_toAccept:
    'Scroll naar beneden om de gebruiksvoorwaarden te lezen en te accepteren.',
  acceptTc_shRegistration:
    'Ik bevestig kennis te hebben genomen van de gebruiksvoorwaarden, de inhoud ervan te begrijpen en te aanvaarden en een kopie ervan te hebben bewaard, geprint of naar mezelf te hebben verzonden.',
  label_shRegistration_infoMsg:
    'Wij verzoeken u vriendelijk om dezelfde persoonsgegevens te vermelden als bekend bij uw financiële instelling. ',
  label_shRegistration_infoMsg2:
    'Wij gebruiken de data die u invult om uw stemmen m.b.t. de algemene vergadering vast te leggen of om u een registratiebewijs te sturen. Meer weten over hoe wij omgaan met uw data? Zie ons ',
  label_shRegistration_infoMsg3: 'Privacy Statement.',
  file_sizeMsg25MB: ' MB. Please upload a file less than or equal to 25 MB.',
  label_all: '--Alle--',
  contact_heading1: 'ABN AMRO Bank N.V. Corporate Broking',
  label_shareholderLogin: 'Aandeelhouder login',
  intermediary_notAllowedToVote:
    'You are not allowed to process voting instructions. Please contact ABN AMRO Bank N.V.',
  intermediary_noPOAAvailableMessage:
    "There are no valid PoA's in place, so at this moment you are not allowed to process voting instructions. Please contact ABN AMRO Bank N.V.",
  intermediary_intermediaryPOAListCheckbox:
    'We hereby confirm that we only upload on behalf of the following Intemediaries, for which a valid power of attorney is available: ',
  intermediary_modalcheck1:
    'We hereby confirm that we have verified the identity of the aforementioned Securities Holder(s), and represent and warrant his/their true identity.',
  intermediary_modalcheck2:
    'We hereby confirm that the aforementioned Securities Holder(s) hold(s) the mentioned Securities of the Issuing Institution at the record date.',
  intermediary_VI_modalcheck3:
    'We hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned Securities Holder(s), with right of substitution, to represent, act and vote on behalf of the Securities Holder(s), in accordance with the Securities Holder(s) instructions, at this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Issuing Institution, the civil law notary or any other person who is entrusted with the registration and/or the proxy voting procedure for this General Meeting.',
  intermediary_modalcheck4:
    'We hereby confirm that no prior voting instructions have been submitted or will be submitted by the aforementioned Securities Holder(s) in connection with this General Meeting and that we nor the aforementioned Securities Holder(s) will submit voting instructions other than those being submitted via the Website.',
  intermediary_modalcheck5:
    'We hereby confirm that we have uploaded the individual voting instruction(s) for which we have submitted a total voting instruction (block vote)',
  intermediary_modalcheck6:
    'We hereby confirm that we will provide the registration certificate(s) to the Securities Holder(s) and instruct the Securities Holder(s) to bring the registration certificate(s) to the General Meeting. In case of the appointment of a Proxy Holder, we hereby confirm that we will provide the Proxy(s) to the Securities Holder(s) and instruct the Securities Holder(s) to print, manually sign and provide this Proxy to the Proxy Holder, who must bring this manually signed document to the General Meeting.',
  intermediary_AR_reject_modalcheck4:
    'We hereby confirm that we will instruct the Securities Holder(s), where applicable, to collect the manually signed document from the Proxy Holder(s).',
  intermediary_AR_modalcheck3:
    'We hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned Securities Holder(s), with right of substitution, to register the Securities Holder(s) for participation for this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Issuing Institution, the civil law notary or any other person who is entrusted with the registration procedure for this General Meeting.',
  intermediary_BulkUpload_int_checkbox5: 'We hereby appoint',
  intermediary_BulkUpload_int_checkbox5_2:
    'as Proxy Holder to represent the Securities Holder(s) listed in this bulk upload at the General Meeting of ',
  intermediary_BulkUpload_int_checkbox5_3: '(the "Company") on',
  intermediary_BulkUpload_int_checkbox5_4:
    " and to exercise the voting rights attached to the Securities listed in this bulk upload on the Securities Holder(s)' behalf in this General Meeting.",
  intDeadline_shdedline_Tooltip:
    'Please note that for the Belgium market this date is always the six calendar day before the date of the meeting.',
  label_proxy: 'Volmacht',
  label_participation_by_proxy : 'Deelname bij volmacht',
  label_vote_by_correspondence: 'Stemmen per brief',
  label_participation_method: 'Participation Method',
  label_select_participation_method: '--Select Participation method--',
  vi_intermediary_individual_disclaimer1: 'Wij stellen hierbij ',
  vi_intermediary_disclaimer1: 'Ik/wij stel/stellen hierbij ',
  vi_intermediary_disclaimer2: ' van ',
  vi_intermediary_disclaimer3:
    ' aan als Volmachtdrager (met recht van substitutie) om de hierboven vermelde Effectenhouder te vertegenwoordigen op de Algemene Vergadering van ',
  vi_intermediary_disclaimer4:
    ' (de "Vennootschap") die zal worden gehouden op ',
  vi_intermediary_disclaimer5: ' om ',
  vi_intermediary_disclaimer6: ' te ',
  vi_intermediary_disclaimer7:
    ' en om de stemrechten verbonden aan de hierboven vermelde Effecten in naam van de Effectenhouder uit te oefenen in de Algemene Vergadering.',
  vi_intermediary_disclaimer_telenet0:
    'Bij afwezigheid van steminstructies aan de volmachtdrager ten aanzien van de verschillende punten op de agenda, of in geval, om welke reden ook, er onduidelijkheid zou bestaan in de meegedeelde steminstructies of indien er gestemd zou moeten worden over beslissingen te nemen door de algemene vergadering aan de orde tijdens de vergadering mits naleving van het Wetboek van vennootschappen, zal de volmachtdrager steeds voor het voorgestelde besluit voorgesteld door de Raad van Bestuur, eventueel zoals het zal worden aangepast, stemmen.\n',
  vi_intermediary_disclaimer8:
    'Ik/wij geef/geven hierbij aan de Volmachtdrager de volgende instructies om te stemmen op de Algemene Vergadering van de Vennootschap:',
  vi_vote_by_correspondence_disclaimer: 'Uw stem wordt rechtstreeks toegewezen aan de emittent van de vergadering en uw stem wordt onmiddellijk bekendgemaakt aan de emittent na de bevestiging van het recht van uw tussenpersoon.',
  vi_shareholder_disclaimer1: 'Ik/wij stel/stellen hierbij ',
  vi_shareholder_disclaimer2: ' van ',
  vi_shareholder_disclaimer3:
    ' aan als Volmachtdrager (met recht van substitutie) om in mijn/onze naam aanwezig te zijn op de Algemene Vergadering van ',
  vi_shareholder_disclaimer4:
    ' (de "Vennootschap") die zal worden gehouden op ',
  vi_shareholder_disclaimer5: ' om ',
  vi_shareholder_disclaimer6: ' te ',
  vi_shareholder_disclaimer7:
    ', om in mijn/onze naam deel te nemen aan de beraadslagingen en de stemrechten verbonden aan de voornoemde Effecten uit te oefenen in overeenstemming met de bovenstaande steminstructies en om alle documenten te ondertekenen en/of alle andere handelingen te stellen ter bevestiging van de uitgebrachte stemmen. ',
  vi_shareholder_disclaimer8:
    'Ik/wij geef/geven hierbij aan de Vote Collector bovenstaande instructies om in mijn/onze naam te stemmen op de Algemene Vergadering van de Vennootschap.',
  vi_shareholder_disclaimer8_with_right_of_substitution:
    'Ik/wij geef/geven hierbij aan de Vote Collector (met recht van substitutie) bovenstaande instructies om in mijn/onze naam te stemmen op de Algemene Vergadering van de Vennootschap.',
  label_tou_registration: 'Registratie',
  label_tou_registration_definitions:
    'de aanmelding voor deelneming aan een welbepaalde Algemene Vergadering via het Registratieportaal;',
  label_tou_registration1: 'Registratieportaal',
  label_tou_registration_definitions1:
    'het gedeelte van de Website via hetwelk de Gebruiker (i) de aanmelding voor deelneming aan een welbepaalde Algemene Vergadering kan voltrekken, alsook (ii) een willekeurige Volmachtdrager kan aanwijzen en steminstructies kan geven voor een welbepaalde Algemene Vergadering;',
  'label_terms_regSection4.1':
    'Het Registratieportaal kan gebruikt worden voor de aanmelding tot deelneming aan een welbepaalde Algemene vergadering.',
  'label_terms_regSection4.2':
    'Om de Registratie te voltrekken, dient de Gebruiker ("Ondertekeningsprocedure"):',
  'label_terms_regSection4.3':
    'De Gebruiker dient de gevraagde gegevens naar waarheid in te vullen in de daartoe bestemde velden in Registratieportaal van de Website. De door de Gebruiker in het Registratieportaal ingevoerde gegevens worden, na ontvangst van een e-mailalert van ABN AMRO, geverifieerd door de Tussenpersoon die in het Registratieportaal werd opgegeven door de Gebruiker. De Tussenpersoon stelt ABN AMRO in kennis van eventueel onjuist verstrekte gegevens. De Tussenpersoon meldt dit vervolgens aan de Gebruiker, die de onjuiste gegevens kan aanpassen tot het einde van de termijn waarbinnen de Gebruiker het Registratieportaal kan gebruiker voor een welbepaalde Algemene Vergadering. De Tussenpersoon verifieert de aangepaste gegevens en, indien alle gegevens juist zijn, ontvangt de Gebruiker daarvan een melding van ABN AMRO. Indien de Gebruiker de onjuiste gegevens niet aanpast binnen de termijn gedurende dewelke de Gebruiker het Registratieportaal kan gebruiken voor een welbepaalde Algemene Vergadering, kan de Registratie van deelneming niet worden voltooid en zal de Registratie geacht worden niet te hebben plaatsgevonden.',
  'label_terms_regSection4.21':
    'het Unieke Identificatienummer dat verschijnt in het Registratieportaal te bezorgen aan de Tussenpersoon, die dit nummer en de identiteit van de Gebruiker zal verifiëren;',
  'label_terms_regSection4.22':
    'deze Gebruiksvoorwaarden te lezen en zijn akkoord hiermee te herbevestigen; en',
  'label_terms_regSection4.23': 'zijn registratie te bevestigen.',
  'label_terms_regSection4.24':
    'Door de hierboven beschreven Ondertekeningsprocedure te voltooien, erkent en aanvaardt de Gebruiker dat de Registratie geldig door hem werd ondertekend (ook ingeval een derde partij, al dan niet met toestemming, de Registratie heeft ondertekend) met een elektronische handtekening. De Gebruiker erkent en aanvaardt dat de elektronische handtekening die wordt gebruikt in het Registratieportaal, meer bepaald de Ondertekeningsprocedure, hetzelfde rechtsgevolg zal hebben als een handgeschreven handtekening.',
  'label_terms_proxySection4.4new':
    'De Volmachtdrager geniet dezelfde rechten in de Algemene Vergadering als de aldus vertegenwoordigde Effectenhouder, en inzonderheid het recht om het woord te voeren, om vragen te stellen en om het stemrecht uit te oefenen.',
  'label_terms_regSection4.4.1new':
    'het Unieke Identificatienummer dat verschijnt in het Volmachtenportaal te bezorgen aan de Tussenpersoon, die dit nummer en de identiteit van de Gebruiker zal verifiëren;',
  'label_terms_regSection4.4.2new':
    'deze Gebruiksvoorwaarden te lezen en zijn akkoord hiermee te herbevestigen; en',
  'label_terms_regSection4.4.3new': 'zijn registratie te bevestigen.',
  'label_terms_proxySection4.6':
    'De Volmacht is geen verzoek tot verlening van een volmacht.',
  'label_terms_proxySection4.7':
    'De Volmacht die voor een welbepaalde Algemene Vergadering wordt gegeven, geldt voor de opeenvolgende Algemene Vergaderingen die met dezelfde agenda worden bijeengeroepen.',
  'label_terms_proxySection4.8':
    'De Volmachten die worden verstrekt vóór de bekendmaking van een aangevulde agenda, in voorkomend geval, blijven geldig voor de op de agenda opgenomen te behandelen onderwerpen waarvoor zij gelden. De Volmachtdrager is niet gemachtigd om te stemmen over de nieuw te behandelen onderwerpen die op de agenda zijn opgenomen zonder instructies.',
  'label_terms_generalProvisions11.5.2new':
    'A Consumer domiciled in Belgium may bring proceedings in relation these Terms of Use and/or the use of the Website before the competent court in the Netherlands, or before the competent court in Belgium. In the latter case, the applicable laws shall be the laws of Belgium.',
  'label_terms_generalProvisions11.5.3new':
    'Proceedings in relation to these Terms of Use and/or the use of the Website may be brought against a Consumer domiciled in Belgium only before the court in Belgium. In this case, the applicable laws shall be the laws of Belgium.',
  'label_terms_definitions1.5header': 'Effecten',
  'label_terms_definitions1.6header': 'EH Ondertekeningsprocedure',
  locale_tou_and: 'en',
  label_tou_securitiesHolder: 'Tussenpersoon',
  label_tou_termsUse: 'Volmacht',
  label_tou_uniqueIdentificationNumber: 'Volmachtdrager',
  label_tou_user: 'Volmachtenportaal',
  label_tou_header4: 'Registratie',
  'label_tou_header4.1': 'REGISTRATIE DOOR DE EFFECTENHOUDER',
  'label_terms_regSHSection4.1.1':
    'Via het Registratieportaal kan een Effectenhouder zich aanmelden tot deelneming aan een welbepaalde Algemene vergadering.',
  'label_terms_regSHSection4.1.2':
    'Om de Registratie (of de intrekking daarvan) te voltrekken, dient de Effectenhouder ("EH Ondertekeningsprocedure"):',
  'label_terms_regSHSection4.1.21':
    'het Unieke Identificatienummer dat verschijnt in het Registratieportaal te bezorgen aan de Tussenpersoon, die dit nummer, de identiteit en de hoedanigheid van de Effectenhouder zal verifiëren;',
  'label_terms_regSHSection4.1.22':
    'deze Gebruiksvoorwaarden te lezen en zijn akkoord hiermee te herbevestigen; en',
  'label_terms_regSHSection4.1.23': 'zijn registratie te bevestigen.',
  'label_terms_regSHSection4.1.3':
    'Door de hierboven beschreven EH Ondertekeningsprocedure te voltooien, erkent en aanvaardt de Effectenhouder dat de Registratie (of de intrekking daarvan) geldig door hem werd ondertekend (ook ingeval een derde partij, al dan niet met toestemming, de Registratie (of de intrekking daarvan) heeft ondertekend) met een elektronische handtekening. De Effectenhouder erkent en aanvaardt dat de elektronische handtekening die wordt gebruikt in het Registratieportaal, meer bepaald de EH Ondertekeningsprocedure, hetzelfde rechtsgevolg zal hebben als een handgeschreven handtekening.',
  'label_terms_regSHSection4.1.4':
    'Na het voltooien van de Registratie, ontvangt de Effectenhouder een registratiebewijs dat hij/zij dient mee te nemen naar de Algemene Vergadering.',
  'label_terms_regSHSection4.1.5':
    'De Effectenhouder dient de gevraagde gegevens naar waarheid in te vullen in de daartoe bestemde velden in het Registratieportaal van de Website. De door de Effectenhouder in het Registratieportaal ingevoerde gegevens worden, na ontvangst van een e-mailalert van ABN AMRO, geverifieerd door de Tussenpersoon die in het Registratieportaal werd opgegeven door de Effectenhouder. De Tussenpersoon stelt ABN AMRO in kennis van eventueel onjuist verstrekte gegevens. De Tussenpersoon meldt dit vervolgens aan de Effectenhouder, die de onjuiste gegevens kan aanpassen tot het einde van de termijn waarbinnen de Effectenhouder het Registratieportaal kan gebruiker voor een welbepaalde Algemene Vergadering. De Tussenpersoon verifieert de aangepaste gegevens en, indien alle gegevens juist zijn, ontvangt de Effectenhouder daarvan een melding van ABN AMRO. Indien de Effectenhouder de onjuiste gegevens niet aanpast binnen de termijn gedurende dewelke de Effectenhouder het Registratieportaal kan gebruiken voor een welbepaalde Algemene Vergadering, kan de Registratie van deelneming niet worden voltooid en zal de Registratie geacht worden niet te hebben plaatsgevonden.',
  'label_tou_header4.2': 'REGISTRATIE DOOR DE TUSSENPERSOON',
  'label_terms_regSHSection4.2.1':
    'Via het Registratieportaal kan een Tussenpersoon, handelend in naam en voor rekening van een Effectenhouder, een Effectenhouder aanmelden tot deelneming aan een welbepaalde Algemene vergadering.',
  'label_terms_regSHSection4.2.2':
    'Om de Registratie (of de intrekking daarvan) te voltrekken, dient de Tussenpersoon ("TP Ondertekeningsprocedure"):',
  'label_terms_regSHSection4.2.21':
    'de identiteit en de hoedanigheid van de Effectenhouder te verifiëren en te bevestigen;',
  'label_terms_regSHSection4.2.22':
    'deze Gebruiksvoorwaarden te lezen en zijn akkoord hiermee te herbevestigen; en',
  'label_terms_regSHSection4.2.23': 'de registratie te bevestigen.',
  'label_terms_regSHSection4.2.3':
    'Door de hierboven beschreven TP Ondertekeningsprocedure te voltooien, erkent en aanvaardt de Tussenpersoon dat de Registratie (of de intrekking daarvan) geldig door hem werd ondertekend (ook ingeval een derde partij, al dan niet met toestemming, de Registratie (of de intrekking daarvan) heeft ondertekend) met een elektronische handtekening. De Tussenpersoon erkent en aanvaardt dat de elektronische handtekening die wordt gebruikt in het Registratieportaal, meer bepaald de TP Ondertekeningsprocedure, hetzelfde rechtsgevolg zal hebben als een handgeschreven handtekening.',
  'label_terms_regSHSection4.2.4':
    'Na het voltooien van de Registratie, ontvangt de Tussenpersoon een registratiebewijs voor de Effectenhouder(s) die hij heeft geregistreerd. De Tussenpersoon dient dit registratiebewijs aan de Effectenhouder(s) te bezorgen, die dit dienen mee te nemen naar de Algemene Vergadering.',
  'label_terms_regSHSection4.2.5':
    'De Tussenpersoon dient de gevraagde gegevens naar waarheid in te vullen in de daartoe bestemde velden in het Registratieportaal van de Website.',
  'label_terms_regSHSection4.2.6':
    'De Tussenpersoon die het Registratieportaal gebruikt, handelend in naam en voor rekening van een Effectenhouder, zal (i) dit uitsluitend doen op basis van en binnen de grenzen van een rechtsgeldige volmacht, (ii) voldoen aan alle vereisten in het kader van of in verband met deze volmacht, en (iii) de documenten waaruit de bevoegdheid om te handelen in naam en voor rekening van de Effectenhouder blijkt op eerste verzoek voorleggen aan ABN AMRO, de Emittent, de notaris of enige andere persoon die belast is met de registratie- en/of volmachtenprocedure voor een welbepaalde Algemene Vergadering.',
  label_downloadPDF_voting: 'Voting instruction per registration PDF',
  label_downloadExcel_voting: 'Voting instruction per registration Excel',
  label_accountLocked:
    'Gebruikersaccount is vergrendeld, stel je wachtwoord opnieuw in.',
  label_technicalError: 'Technical Error, Please try again later',
  label_sessionExpired: 'User Session has expired, Please Login to continue',
  label_selectVC: '--Selecteer entiteit--',
  label_confirmVotes_prevGm:
    'Bevestigde steminstructies van afgelopen vergaderingen',
  label_no_ar_available: 'Geen registraties aanwezig',
  label_no_vi_available: 'Geen steminstructies aanwezig',
  label_approved_RC: 'Goedgekeurd, Annuleringsverzoek ingediend',
  label_cancellation_requested: 'Annuleringsverzoek ingediend',
  extnbtn3: 'Cancelled',
  label_noAR: 'No attendance requests available in this status',
  label_noVI: 'No voting instructions available in this status',
  label_no_confVI_available: 'Geen bevestigde steminstructies aanwezig',
  label_votingExists_error:
    "Steminstructie reeds aanwezig voor dit security type en intermediair. Zie 'Mijn uitgebrachte stemmen/registraties'",
  label_cancelReg_info:
    'Onderstaande registraties worden geannuleerd zodra de steminstructie is bevestigd door uw intermediair',
  label_cancelVoting_info:
    'Onderstaande steminstructie wordt geannuleerd zodra dit registratie verzoek is bevestigd door uw intermediair',
  label_noVCavailable: 'No vote collector available',
  label_noIntermediaryAvailable: 'No intermediary available',
  label_notVotable: 'Geen stempunt',
  label_notVotableAgenda: 'Er zijn geen stempunten voor deze vergadering',
  modal_header_selectLang: 'Selecteer een taal om te downloaden',
  label_accessDate: 'Datum start registratie',
  mail_sentToIssuer_text1: 'Mail has been sent already on ',
  mail_sentToIssuer_text2: '. Please check before sending again.',
  label_updatePwd: 'Wachtwoord wijzigen',
  modal_areYousure_changePwd:
    'Weet u zeker dat u het wachtwoord wilt wijzigen?',
  label_update_accountDetails: 'Bijwerken profiel',
  modal_areYouSure_updateDetails:
    'Weet u zeker dat u het profiel wilt bijwerken?',
  label_noGMMessage: 'No general meeting available',
  label_proxyTitle: 'Proxy title',
  label_proxyCountry: 'Proxy country',
  label_proxyCity: 'Proxy city',
  label_noDataGMIssuerMessage: 'No general meeting available for issuer',
  label_utc: 'UTC',
  label_uploadBOFile: 'Upload BO file',
  label_uploadBOFileError:
    'Uploading a BO file in ".xlsx" is mandatory if the user type is NOMI or TRUS',
  label_checkShareholder: 'Check for shareholder',
  label_edit_IN_AR_BOFile: 'Wijzigen registratie',
  label_total_voting_instrunction: 'Total voting instructions',
  label_voting_inst_per_reg: 'Voting instruction per registration',
  label_pdf: 'Pdf',
  label_excel: 'Excel',
  label_cancelReq: 'Annuleer verzoek',
  label_reqCancellation: 'Verzoek annulering',
  label_dnldRegCerti: 'Download registratiebewijs',
  label_addIntSucessMsg: 'La demande d\u2019intermédiaire a été créée',
  label_pwdSuccessMsg: 'Wachtwoord is bijgewerkt',
  lable_accountUpdateSuccessMsg: 'Gebruikersaccount is bijgewerkt',
  label_noShareclassAvailable: 'No shareclass available',
  label_selectRegCertLang: 'Select registration certificate language',
  label_sendRegTicketToShareholder:
    'Send registration tickets to shareholders based on provided mail address after upload in ',
  label_pn: 'Numéro de téléphone',
  label_coc: 'Kamer van koophandel',
  label_vat: 'BTW nummer',
  label_contact_details_footer: 'Contactgegevens',
  label_noSHGMFound: 'Er zijn momenteel geen algemene vergaderingen',
  label_voting_disabled: 'Stemmen is uitgeschakeld voor deze vergadering.',
  label_attendance_disabled:
    'Aanwezigheid is uitgeschakeld voor deze vergadering.',
  checkPattern_Pwd_length: 'Wachtwoord dient minimal 8 tekens lang te zijn',
  label_DobInvalid: 'Geboortedatum is verplicht (dd-MM-jjjj)',
  label_dobFutureError: 'Geboortedatum kan niet in de toekomst liggen',
  label_dateFutureError: 'Datum mag niet in de toekomst liggen',
  label_dateInvalid: "Datum graag invullen in 'jj-MM-aaaa'",
  label_voteNotPossible:
    'Het is niet mogelijk om steminstructies in te dienen voor deze vergadering',
  label_attendNotPossible:
    'Het is niet mogelijk om aan te melden voor deze vergadering',
  label_attendNotEnabled1:
    'Het is helaas niet (meer) mogelijk voor aandeelhouders om aanwezig te zijn op de AvA. U kunt contact op nemen met',
  label_attendNotEnabled2:
    'Volmacht / steminstructies kunnen worden gegeven met de ‘via steminstructie’ knop. Wanneer virtuele aanwezigheid beschikbaar is gemaakt, kunnen aandeelhouders zich aanmelden voor virtuele aanwezigheid via de knop ‘virtueel bijwonen’',
  label_attendDisabled1_intermediary:
    'It is unfortunately not possible for shareholders to attend the meeting in person. Therefor create new registration and also bulk upload is not allowed. Your clients (shareholders) should contact the company ',
  label_attendDisabled2_intermediary:
    ' for approval to attend the meeting in person.',
  label_voteAttendNotAllowed:
    'Het is niet meer mogelijk om steminstructies in te dienen of u aan te melden voor deze vergadering',
  label_voteAttendNotAllowedAccessDate:
    'Stem / Attend wordt ingeschakeld na de toegangsdatum voor de aandeelhouders',
  label_shareholderExists:
    'Dit mail adres is reeds geregistreerd, u kunt direct inloggen of een nieuw wachtwoord aanvragen als u die niet meer weet.',
  label_oldPassIncorrect: 'Oud wachtwoord is onjuist',
  label_existLastPassList:
    'Dit wachtwoord bestaat in de laatste 12 wachtwoordenlijst',
  label_passLengthIncorrect: 'Wachtwoordlengte is incorrect',
  label_dataNotFound: 'Gegevens niet gevonden voor de gegeven invoer',
  label_passNotMatch: 'Wachtwoord komt niet overeen',
  label_accessDenied: 'Toegang geweigerd',
  label_globalError_1:
    'Sorry, er is een fout opgetreden voor de gevraagde bewerking. Andere bewerkingen kunnen werken, maar kunnen niet worden gegarandeerd.',
  label_globalError_2:
    'Voor directe communicatie met technische ondersteuning e-mail naar corporate.broking@nl.abnamro.com met details over de actie die u heeft uitgevoerd en wij nemen contact met u op.',
  label_error: 'Fout!',
  label_newVote: 'Nieuwe instructie',
  label_newRegistration: 'Nieuwe registratie',
  label_createRTS: 'Create new RTS request',
  label_editRTS: 'Edit RTS request',
  label_continue: 'Continue',
  label_conf_votes: 'Bevestigde steminstructies',
  lable_AR_proxy_error:
    'Registratie (om de meeting zelf bij te wonen) is al aanwezig voor deze vergadering',
  lable_AR_shares_error:
    'U kunt niet stemmen of registreren voor deze vergadering, omdat met het aantal aandelen dat u opgeeft, u het het totale aantal uitstaande aandelen voor deze aandelenklasse overschrijdt.',
  label_edit_extn_request: 'Edit Extension Request',
  label_shGMNotFound:
    'Momenteel zijn er geen algemene vergaderingen beschikbaar ',
  label_forYourSearchTerm: 'voor jouw zoek opdracht',
  label_defaulProxyForbulkUpload:
    "Add 'Iedere vertegenwoordiger van de VEB' as proxy for all attendance requests in this upload file",
  label_request_date: 'Request date',
  label_dateandtime: 'Date and time',
  label_exclamation: '!',
  label_noDWTavailable: 'There are no DWT events available',
  label_noDataGMIssuerMessage_DW: 'No DWT events available for issuer',
  label_portalInfoExample:
    'This portal is still under construction and the information shown is an example',
  label_error_nameInvalid:
    'Naam is ongeldig Minimaal 2 geldige karakters worden verwacht',
  label_thereAreNo: ' There are no',
  label_eventsAtMoment: 'tax reclaim events at this moment',
  label_draft: 'draft',
  label_pending: 'pending',
  label_toBePublished: 'to be published',
  label_live: 'live',
  label_completed: 'completed',
  label_cancelled: 'cancelled',
  label_documentLessThan25mb: 'Document should be less than 25mb',
  label_iWouldAttend:
    'Ik wil graag registreren om aanwezig te zijn tijdens de vergadering',
  label_iWouldVote: 'Ik wil graag steminstructies indienen voor de vergadering',
  label_checkSCMsg:
    'Add check to prevent register or vote to wrong share class',
  label_sendEmailEuro: 'Send email to Euroclear',
  label_downloadEuroclearReport: 'Download Euroclear report',
  label_agentWS: 'Agent workstation',
  label_sendVIreg_toEuroclear:
    'Option to send registrations and voting instructions to Euroclear',
  label_proceed: 'Doorgaan',
  label_dear: 'Dear ',
  label_noShareholderDetails: 'No shareholder details found',
  label_euroclear_mesage: 'Mail has been sent already on',
  label_plsCheck_mesage: 'Please check before sending again.',
  label_reSendEmailEuro:
    'Mail has been already sent. Do you want to send it again?',
  label_subject_required: 'Subject is required.',
  label_emailboday_required: 'Email body is required.',
  sendVIreg_toEuroclear_Tooltip: 'This option is mandatory for Shell',
  label_nwkError_1: 'Please validate your internet connectivity and check:',
  label_download_intermediary_instructions: 'Download accepted instructions',
  label_nwkError_2: '-- Checking the network cables, modem, and router',
  label_nwkError_3: '-- Reconnecting to Wi-Fi',
  label_nwkError_4:
    '-- Refresh the browser (press F5) once the connectivity issue is resolved',
  label_network_error_lost: 'Internet connection lost',
  label_network_error_1:
    'The page could not be loaded as there is or was an issue with your internet connection.',
  label_network_error_2:
    'Please check your connection and refresh the browser (press F5) once the connection issue is resolved.',
  label_noVCavailable_forVote:
    'This general meeting and shareclass does not have any vote collector',
  label_aegon_issuer:
    'De aandeelhoudersvergadering is live te volgen op de website van Aegon',
  label_instruction_not_available:
    'There are no accepted instructions to download',
  vi_intermediary_disclaimer_telenet1:
    'IDw Consult BV, met als vaste vertegenwoordiger de heer Bert De Graeve, is bestuurder van Telenet Group Holding NV en heeft bijgevolg een potentieel belangenconflict zoals bepaald in artikel 7:143, §4 van het Wetboek van vennootschappen en verenigingen. In geval van potentieel belangenconflict zal hij enkel stemmen in uitvoering van de volmacht overeenkomstig de specifieke steminstructies in deze volmacht opgenomen. Bij gebrek aan een specifieke steminstructie, zal hij geacht worden de specifieke instructie te hebben ontvangen voor dit punt te stemmen.',
  vi_intermediary_disclaimer_telenet2: '',
  lable_numberOfShares_error: 'Invalid number of shares',
  lable_5posOnly: 'Initialen kunnen maximaal 5 posities lang zijn',
  label_votingAdvise: 'Voting advise',
  label_shareholder_link: 'www.abnamro.com/shareholder',
  label_intermediary_link: 'www.abnamro.com/intermediary',
  label_proceedToLogout: 'Doorgaan naar ',
  label_asACustomerOf: 'Als klant van ',
  label_voteViaIntermediary:
    'kunt u alleen aanmelden voor de vergadering via de site van ',
  label_alex_binkBank_warning:
    ' kunt u alleen steminstructies indienen via de site van',
  label_reloadCancelError:
    'Please choose different meeting or a different shareclass to proceed.',
  label_teleNumber: 'Telefoonnummer',
  label_addresseeEmail: 'E-mail adres',
  lable_contactDetails: 'Contactgegevens',
  pwdChanged_successMsg_proceed:
    'Password changed successfully. Redirecting to login page...',
  pwdExpiry_msg:
    'Your password is expired, please change your password. Be aware that you cannot use the last 12 used passwords.',
  label_viewVotingInstruction: 'View voting instruction',
  label_login_failed: 'Login redirect failed ',
  label_noVIAvailable: 'No voting instruction available for ',
  label_of: 'of',
  label_pwd_requirement: 'Please check the password requirements',
  label_one_email:
    "Please ensure that only one valid email id is entered in the 'To' address field",
  lable_mobile_number: 'Mobiel telefoon',
  label_mobile_number_reenter: 'Herhaal uw mobiele telefoonnummer',
  lable_mobile_number_guidelines:
    'Selecteer de juiste landcode en vermeld uw mobiele telefoonnummer volgens onderstaande instructies:',
  lable_mobile_number_numeric:
    '- Het mobiele nummer mag alleen uit nummers bestaan',
  lable_mobile_number_length: '- De maximaal toegestane lengte is 15 nummers',
  label_mobile_number_numeric_error:
    'Het mobiele nummer mag alleen uit nummers bestaan.',
  label_phone_number_exists_error: 'Dit telefoonnummer is al in gebruik.',
  label_country_code_error: 'Please select the country code.',
  label_virtual_participation: 'Virtueel bijwonen',
  label_virtual_participation_no_voting:
    'Virtueel bijwonen, alleen stemmen via proxy',
  label_attend_virtualy: 'Virtuele deelname',
  label_attend_sh: 'Aanmelden',
  label_vote_sh: 'Stemmen',
  label_virtualPartNotAllowedAccessDate:
    'Virtuele deelname wordt ingeschakeld na de toegangsdatum voor de aandeelhouders',
  label_register_virtualParticipation_step_counter_1:
    'Aandelen en rekeningnummer',
  label_register_virtualParticipation_step_counter_2: 'Telefoon, Voorwaarden',
  label_register_virtualParticipation_step_counter_3: 'Verificatiecode',
  label_register_virtualParticipation:
    'Registreren voor virtuele deelname tijdens vergadering',
  label_register_virtualParticipation_verify_phone:
    'Verificatie van het telefoonnummer',
  label_register_virtualParticipation_acknowledge: 'Erkennen en Accepteren',
  label_register_virtualParticipation_enter_code: 'Code invullen',
  label_register_virtualParticipation_success:
    'Uw registratie voor virtuele deelname is succesvol',
  label_register_virtualParticipation_success_message:
    'Uw verzoek tot virtuele deelname aan de vergadering, dient te worden goedgekeurd door uw intermediary.',
  label_register_virtualParticipation_please_confirm:
    'Graag uw beleggingsrekening en mobiele telefoonnummer bevestigen aan uw intermediary',
  label_register_virtualParticipation_question: 'Vraag',
  label_register_virtualParticipation_question_message:
    'Mocht u vragen hebben, aarzel dan niet contact op te nemen met de ABN AMRO Bank.',
  label_verfication_code_sent:
    'Een nieuwe verificatiecode is naar uw mobiele telefoon verstuurd.',
  label_verifying_code: 'Code verifiëren...',
  label_verfication_sending_new_code: 'Nieuwe code verzenden',
  label_otp_resend_message_part1: 'De knop "Opnieuw versturen" wordt binnen ',
  label_otp_resend_message_part2: ' seconden weer geactiveerd. Wacht alstublieft eerst op een eenmalige code (OTP).',
  label_send_again: 'Opnieuw versturen',
  label_code: 'Code',
  label_back: 'Terug',
  label_verify: 'Verifiëren',
  label_contact_intermediary: 'Contacteer Intermediary',
  label_join_meeting: 'Deelnemen aan vergadering',
  label_to_my_events: 'Naar mijn events',
  label_account: 'Rekeningnummer',
  label_terms_vp1:
    'I/We herby confirm that I/We have read, understood and consented the',
  label_terms_vp2:
    'as well as having saved, printed or sent myself/ourselves a copy thereof.',
  label_contact: 'Contact',
  label_helpdesk: 'Helpdesk',
  label_shares: 'Aandelen',
  label_SAN_error: 'Graag een geldige beleggingsrekening invullen',
  label_SAN_exists_error:
    'Securities account number already exists for this meeting',
  lable_TermsAndConditions: 'Voorwaarden',
  lable_phoneNumberVerification:
    'Zodra dit telefoonnummer is geverifieerd, is het niet meer mogelijk het te wijzigen. Het zal dan gebruikt moeten worden tot na afloop van de vergadering.',
  label_virtualParticipationRequests: 'Virtual participation requests',
  label_no_virtualParticipation:
    'Geen verzoeken tot virtueel bijwonen beschikbaar',
  lable_mobileNumMismatch: 'de ingevulde telefoonnummers komen niet overeen',
  label_phoneNumber_exists_meeting_error:
    ' U staat al geregistreerd voor deze meeting met een ander telefoonnummer. Controleer en corrigeer, waar benodigd, de registraties in uw registratieoverzicht..',
  label_numeric_value_error: 'Graag alleen nummers invullen',
  label_meeting_details: 'Gegevens vergadering',
  label_join: 'Deelnemen',
  label_join_meeting_enable_hover_text:
    'Selecteren om virtueel deel te nemen aan de vergadering',
  label_join_meeting_disable_hover_text1:
    ' U kunt verbinding maken met de vergadering van',
  label_join_meeting_disable_hover_text2: 'tot middernacht',
  label_virtual_participation_leave_cbp_warning:
    'U staat op het punt om de ABN AMRO-portal voor elektronisch stemmen te verlaten en door te gaan naar de applicatie/website voor virtuele deelname. Deze applicatie/website wordt niet verzorgd of bediend door ABN AMRO. ABN AMRO is dan ook niet verantwoordelijk voor de inhoud, de beschikbaarheid en de functionaliteit ervan. Voor vragen over of problemen met de applicatie/website voor virtuele deelname kunt u bellen naar +3120 628 6070.',
  label_button_accept_and_proceed: 'Accepteren en Doorgaan',
  label_helpDesk_Number: '+31 20 628 6070',
  label_Important_Information: 'Belangrijke informatie',
  label_cbp_email: 'corporate.broking@nl.abnamro.com',
  label_dual_factor_authentication_success:
    'U bent succesvol doorgeleid naar de applicatie/website voor virtuele deelname',
  label_authentication_generic_message:
    'We kunnen uw verzoek helaas niet verwerken. Graag contact opnemen met de helpdesk.',
  label_2FA_OTP_fail:
    'Creatie / verificatie van de code (OTP) is niet mogelijk. Graag opnieuw proberen.',
  label_Invalid_OTP_msg:
    'Ongeldige / Verlopen code (OTP). Graag opnieuw verzenden, om een nieuwe code te ontvangen.',
  CBP_ERR_573:
    'Deze beleggingsrekening staat al geregistreerd voor deze vergadering',
  label_like_to_attend_virtually:
    'Ik wil de vergadering graag virtueel bijwonen',
  label_virtual_Meeting_Id_mandatory_msg:
    'For virtual participation, meeting id is mandatory',
  label_virtual_participation_provider_required_msg: 'A provider is required',
  label_download_registration_list: 'Download registration list',
  label_vp_registration_risk:
    'U kunt hieronder bevestigen dat u de Algemene Vergadering virtueel wilt bijwonen.\n\nAls u door wilt gaan, onderkent en aanvaardt u de risico’s die aan het virtueel bijwonen van en deelnemen aan de Algemene Vergadering inherent zijn door in het daarvoor bestemde vakje hieronder te klikken en krijgt u toegang tot de sectie Virtuele Deelname door op de knop “Accepteren en Doorgaan” te klikken.\n\nDe risico’s die inherent zijn aan het virtueel bijwonen van en deelnemen aan de Algemene Vergadering omvatten onder meer: storingen in apparatuur voor virtueel vergaderen, software, netwerkinfrastructuur, servers, internet- of telefoonverbindingen, video- of spraakapparatuur die al dan niet het gevolg zijn van een informatiebeveiligingsincident (waaronder mede begrepen onbevoegd gebruik, hacking, een Denial of Service (DoS) aanval, een toegangsontzegging, storingen of binnendringing als gevolg van diefstal of onrechtmatige onderschepping van een wachtwoord of code voor netwerktoegang alsmede andersoortige cyberaanvallen) of die veroorzaakt worden door mechanische storingen, zoals stroomuitval tijdens de Algemene Vergadering en andere onvoorziene technische problemen of verstoringen met betrekking tot de Website in het algemeen, de sectie Virtuele Deelname, de Algemene Vergadering of de door u, de aandeelhouder, gebruikte apparatuur en/of verbindingen.\n\nDoor in de vakjes hieronder te klikken, bevestigt u dat:\n',
  label_vp_registration_risk_item1:
    'u de bovenbeschreven risico’s onderkent en aanvaardt en dat u onze aan inschrijving voor en gebruik van de sectie Virtuele Deelname verbonden voorwaarden gelezen en begrepen hebt.',
  label_vp_registration_risk_item2:
    "u gemachtigd bent tot het accepteren van deze risico's, omdat u zelf de aandeelhouder bent of hiervoor een volmacht heeft verkregen.",
  label_vp_registration_risk_item3:
    'u het beleid inzake de hybride/virtuele stemmen door aandeelhouders, dat door de uitgevende instelling van toepassing is verklaard, gelezen en begrepen hebt en ermee instemt. Dit beleid vindt u hier: ',
  label_vp_registration_risk_item3_no_link:
    'u erkent, indien beschikbaar, het beleid met betrekking tot de hybride/virtuele vergadering/deelname en de door de uitgevende instelling van toepassing verklaarde privacyverklaring hebt gelezen, begrepen en ermee instemt.',
  label_vp_registration_risk_document:
    'De gebruiksvoorwaarden die van toepassing zijn op het gebruik van de website in het algemeen en de sectie Virtuele Deelname in het bijzonder, vindt u hier: ',
  label_policy_issuing_institution: 'Beleid Uitgevende Instelling',
  label_virtual_Meeting_date_mandatory_msg: 'Enable join by date is required',
  label_Enable_join_date_error_msg:
    'Enable join by date should be greater than the shareholder access date',
  label_Enable_join_by: 'Enable join by',
  label_voting_options: 'Stem Opties',
  label_record_date_required: 'Record date is required',
  label_record_date_format:
    'Record date format should be like \n 01-April-2017\n',
  label_record_date_before_GM:
    'Record date should be before general meeting date.',
  label_record_date_after_convocation:
    'Record date should be after convocation date.',
  label_publication_date_required: 'publication date is required.',
  label_publication_date_format:
    'publication date format should be like \n 01-April-2017\n.',
  label_publication_date_before_GM:
    'publication date should be before general meeting date.',
  label_publication_date_after_convocation:
    'publication date should be after convocation date.',
  label_gmProductType: 'GM',
  virtual_participation_allowed_tooltip:
    'Virtual participation can only be disabled when no pending or approved virtual participation requests exist for the meeting',
  registered_share_help_text:
    ' Geef aan met welke type aandelen u graag zou willen participeren. Als u aandelen houdt via het aandeelhoudersregister van de vennootschap, graag register aandelen selecteren.',
  label_intermediaryHelpText:
    'De intermediair is de instelling, waar u uw beleggingsrekening aanhoudt',
  label_approved_registrations: 'Approved registrations',
  label_approved_securities: 'Approved securities',
  label_pending_registrations: 'Pending registrations',
  label_total_pending_registrations: 'Total pending registrations',
  label_overview_of_live_general_meeting: 'Overview of live general meeting',
  label_modal_header_send_preview: 'Send preview',
  label_modal_confirm_template_updated: 'Template updated',
  label_modal_confirm_preview_sent: 'Preview email is sent',
  label_modal_failed_sent_failed: 'Sending the preview failed',
  label_modal_preview_sending: 'Sending preview...',
  CBP_ERR_604: 'reCAPTCHA verificatie mislukt',
  CBP_ERR_227:
    'Dit mail adres is reeds geregistreerd, u kunt direct inloggen of een nieuw wachtwoord aanvragen als u die niet meer weet.',
  CBP_ERR_572: 'Dit telefoonnummer is al in gebruik.',
  label_status_LIVE: 'Live',
  label_status_PENDING: 'Pending',
  label_enterNumberOfSecurities: 'Geef aantal aandelen',
  vi_disclaimer_greenyard1:
    'Indien u een volmacht heeft gegeven voor de buitengewone algemene vergadering van aandeelhouders, dan dient de vervolledigde en ondertekende volmacht in origineel hetzij aan de Vennootschap te worden bezorgd per post, op het volgende adres: ',
  vi_disclaimer_greenyard2:
    'Greenyard NV, t.a.v. mevrouw Fran Ooms, Strijbroek 10, 2860 Sint-Katelijne-Waver (België), hetzij op de buitengewone algemene vergadering van aandeelhouders te worden neergelegd door de gevolmachtigde. Indien de Vennootschap de originele volmacht niet heeft ontvangen vóór de aanvang van de buitengewone algemene vergadering van aandeelhouders, dan is de volmacht ongeldig.',
  ie11_xlsx_download_warning:
    'Het downloaden van .xlsx wordt niet volledig ondersteund door IE11. Mocht de download mislukken, probeer het dan in een andere browser (Chrome, Firefox, Safari, of Edge).',
  label_more_info: 'Meer info',
  toolTip_doNotPublish:
    'When selected the shareclass will not be published to the market and to normal shareholders. (When all shareclasses of a meeting are selected, the entire meeting itself is invisible to the market and shareholders)',
  label_sh_different_custodian_message: `Als u bij nog een andere depotbank ook stemgerechtigde stukken heeft, <a href="mailto:${GM_CONTACT_EMAIL}">neem dan even contact met ons op</a>`,
  label_updateOutstandingShares: 'Update Outstanding shares',
  label_updateOutstandingSharesConfirmation:
    'Are you sure you want to update the Total Outstanding Shares?',
  label_copyOutstandingSharesCsd: 'Copy Outstanding shares Csd',
  label_copyOutstandingSharesCsdConfirmation:
    'Are you sure you want to copy the Total Outstanding Shares via CSD to the Total Outstanding Shares?',
  label_voteExecutionConfirmation: 'Vote Execution Confirmation',
  label_vecStarted:
    'Meeting Vote Execution Confirmation is in progress. You will be informed by e-mail when finished.',
  label_vecNoVotingInstructions: 'No voting instructions to confirm',
  label_updateInstruction: 'Update Instruction',
  label_updateInstructionConfirmation:
    'Are you sure you want to update this instruction?',
  label_cancelInstruction: 'Cancel Instruction',
  label_cancelInstructionConfirmation:
    'Are you sure you want to cancel this instruction, this action cannot be undone.',
  label_updateShareplanHolder: 'Update Shareplan Holder',
  label_updateShareplanHolderConfirmation:
    'Updating the number of shares will also update the number of shares for a potential registration for this Shareplan Holder.',
  label_shareholderGmCategory0: 'Vergaderingen met stem opties',
  label_shareholderGmCategory1: 'Vergaderingen met deadline in het verleden',
  label_shareholderGmCategory2: 'Vergaderingen met de toegangsdatum in de toekomst',
};
